import React, { useEffect } from "react";
import { HistoryContainer } from "../../../../state-containers/History";
import { useTimeZone } from "../../../../helpers/timeZone";

// Components
import Nav from "../../../../components/nav/Nav";
import Container from "../../../../components/container/ContainerStyles";
import PhonePreview from "../../../../components/phonePreview/PhonePreview";
import { toast } from "../../../../components/toast/Toast";

// Segments
import TopContainer from "./segments/TopContainer";
import AddedSpecialHours from "./segments/AddedSpecialHours";
import OpeningsTimeContainer from "./segments/OpeningsTimeContainer";

// Settings
import { SettingsKey } from "../../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

// State
import { VenueContainer } from "../../../../state-containers/Venues";
import { AddSpecialHoursStateContainer } from "../../../../state-containers/AddSpecialHour";

// Styling
import { StyledAddSpecialHours } from "./AddSpecialHoursStyles";

function AddSpecialHours() {
  const settings = SettingsContainer.useContainer();
  const { selectedVenue, setSelectedVenueTimeZone } =
    VenueContainer.useContainer();
  const history = HistoryContainer.useContainer();
  const { getTimeZone } = useTimeZone();
  const { addedOpeningTimes } = AddSpecialHoursStateContainer.useContainer();

  useEffect(() => {
    if (!selectedVenue.data) {
      toast.error(
        settings.string(SettingsKey.AddSpecialHoursNoVenueSelectedToast),
        {
          position: "bottom-center",
        },
      );
      history.push("/manage-venues");
    } else {
      toast.dismiss();
    }
  }, [history, selectedVenue, settings]);

  useEffect(() => {
    if (selectedVenue.data) {
      getTimeZone(selectedVenue.data).then((res) => {
        setSelectedVenueTimeZone(res);
      });
    }
  }, [selectedVenue, getTimeZone, setSelectedVenueTimeZone]);

  return (
    <Nav
      back={() => history.goBack("/manage-venues/special-hours")}
      title={settings.string(SettingsKey.AddSpecialHoursPageTitle)}
    >
      <StyledAddSpecialHours>
        <TopContainer />
        <div className="content">
          <AddedSpecialHours />
          <div className="right">
            <Container>
              {selectedVenue.data && (
                <PhonePreview
                  venue={selectedVenue.data}
                  specialHours={{
                    label: addedOpeningTimes.label,
                    dates: addedOpeningTimes.dates,
                  }}
                />
              )}
            </Container>
            <OpeningsTimeContainer />
          </div>
        </div>
      </StyledAddSpecialHours>
    </Nav>
  );
}

export default AddSpecialHours;
