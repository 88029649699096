import React from "react";
import { StyledToast } from "./ToastStyles";
import { ToastContainer, toast } from "react-toastify";

function Toast() {
  return (
    <StyledToast>
      <ToastContainer />
    </StyledToast>
  );
}

export default Toast;
export { toast };
