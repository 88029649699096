import React from "react";
import Modal from "../../../components/modal/Modal";

interface Props {
  open: React.ComponentProps<typeof Modal>["open"];
  onClose: React.ComponentProps<typeof Modal>["onClose"];
}

const OverlayMessageModal = (props: Props) => (
  <Modal {...props} title={"foo"} actions={<></>}>
    bar
  </Modal>
);

export default OverlayMessageModal;
