import { Venue } from "../../api/types/venues";
import { VenueContainer } from "../../state-containers/Venues";
import { useClientState } from "../../helpers/useClientState";
import uniq from "lodash/uniq";
import { hasMultipleAddressLines } from "../../api/types/guards";

interface Filter {
  key: string;
  text: string;
  fn(v: Venue): boolean;
}

export function useSuggestions(defaults: Record<string, string>) {
  const { venues } = VenueContainer.useContainer();
  const [query, setQuery] = useClientState("");
  const [selectedFilters, setQueryFilters] = useClientState<Filter[]>([]);
  const _at = query.lastIndexOf("@");
  const _semi = query.lastIndexOf(":");

  // venues with applied filters but before applying search query
  const filteredVenues = selectedFilters.reduce(
    (A, qf) => A.filter(qf.fn),
    venues.data?.venues ?? [],
  );

  // venues with applied filters & search query
  const queriedVenues = filteredVenues.filter((v) =>
    (hasMultipleAddressLines(v)
      ? [v.name, String(v.venueId), v.line1, v.line2, v.town, v.postcode]
      : [v.name, String(v.venueId), v.line1, v.town, v.postcode, v.fullAddress]
    ).some((p) => p.toLowerCase().includes(query.slice(0, _at).toLowerCase())),
  );

  // suggestions to hint autocomplete for user
  const suggestions = (() => {
    // check for "@no_semi_colon_here" pattern -> show predefined suggestions
    if (/@[^:]*$/.test(query)) {
      const __afterAt = query.slice(_at + 1).toLowerCase();

      return Object.entries(defaults)
        .filter(([, x]) => String(x).toLowerCase().includes(__afterAt))
        .map(([key, text]) => ({
          text,
          value: key + ":",
          active: selectedFilters.map((qf) => qf.key).includes(key),
        }));
    }

    // check for "@foo:" pattern -> show available options as suggestions
    const property = /@.*:/.test(query) && query.slice(_at + 1, _semi);
    if (property) {
      const __afterSemi = query.slice(_semi + 1).toLowerCase();

      return uniq(filteredVenues.map((v) => v[property]))
        .filter((x) => x !== undefined && x !== "")
        .filter((x) => String(x).toLowerCase().includes(__afterSemi))
        .map((x) => ({ text: `${x}`, value: `"${x}"` }));
    }

    // else show no suggestions
    return [];
  })().sort((a, b) => a.text.localeCompare(b.text));

  function handleFilterClick() {
    setQuery((q) => q + "@");
  }

  function removeFilter(targetFilter: Filter) {
    setQueryFilters((prev) => prev.filter((qf) => qf !== targetFilter));
  }

  /**
   * sets state to update the value of the input element,
   * but first checks if user had type a filter expression - `@foo:"bar"`
   *
   * if such a pattern is present - remove it from the input element and
   * apply it as a filter ( show element if `element.foo === "bar"` )
   */
  function handleInput(value: string) {
    const hasFilter = /@.*:".*"$/.test(value);
    if (hasFilter) {
      const __at = value.lastIndexOf("@");
      const __semi = value.lastIndexOf(":");
      const __afterAt = value.slice(__at + 1, __semi);
      const __afterSemi = value.slice(__semi + 2, value.length - 1);
      const displayName = defaults[__afterAt] ?? __afterAt;

      const filter: Filter = {
        key: __afterAt,

        text:
          __afterSemi === "true"
            ? displayName
            : __afterSemi === "false"
            ? "Not " + displayName
            : !isNaN(+__afterSemi) // if it's a numeric string
            ? `${displayName}: ${__afterSemi}`
            : __afterSemi,

        fn: (venue: Venue) => String(venue[__afterAt]).includes(__afterSemi),
      };

      setQueryFilters((a) => [...a, filter]);
      value = value.slice(0, __at);
    }
    setQuery(value);
  }

  function handleSelect(value: string, isActive: boolean) {
    if (isActive) {
      setQueryFilters((prev) => prev.filter((qf) => !value.startsWith(qf.key)));
      setQuery((q) => q.slice(0, q.length - 1));
    } else {
      const __last = Math.max(query.lastIndexOf("@"), query.lastIndexOf(":"));
      const __clearUntil = __last !== -1 ? __last + 1 : undefined;
      handleInput(query.slice(0, __clearUntil) + value);
    }
  }

  return {
    query,
    suggestions,
    queriedVenues,
    selectedFilters,

    handleInput,
    handleSelect,
    handleFilterClick,
    removeFilter,
  };
}
