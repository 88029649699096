import React from "react";
import { RCodeContainer } from "../../state-containers/RCode";
import { StyledRCodeSelector } from "./RCodeSelectorStyles";

export function RCodeSelector() {
  const { availableRCodes, selectedRCode, setSelectedRCode } =
    RCodeContainer.useContainer();

  return availableRCodes.length > 1 ? (
    <StyledRCodeSelector
      value={selectedRCode}
      options={availableRCodes.map((rcode) => ({
        key: rcode,
        text: rcode,
        value: rcode,
      }))}
      onChange={(event, data) => {
        setSelectedRCode(data.value as string);
      }}
    />
  ) : null;
}
