import React, { useState, useEffect, useMemo } from "react";
import { Input, PopupProps, InputProps } from "semantic-ui-react";
import { StyledTimeSelect } from "./TimeSelectStyles";
import moment from "moment-timezone";
import TimeSelectPopUp from "./segments/TimeSelectPopUp";
import debounce from "lodash/debounce";

interface Props extends PopupProps {
  input: InputProps;
  timeValue: {
    hour: number;
    minute: number;
  };
  updatefunc: (val: { hour: number; minute: number }) => void;
}

function TimeSelect({
  input,
  flowing = true,
  hoverable = true,
  timeValue,
  updatefunc,
  ...props
}: Props) {
  const [inputVal, setInputVal] = useState(
    moment().hours(timeValue.hour).minutes(timeValue.minute).format("HH:mm"),
  );

  function handleOnInput(inputValue: string) {
    const hours = inputValue.substr(0, inputValue.indexOf(":"));
    const minutes = inputValue.substr(inputValue.indexOf(":") + 1);
    if (inputValue.includes(":")) {
      debounceFunc({ hour: +hours, minute: +minutes });
    } else {
      debounceFunc({ hour: +inputValue, minute: 0 });
    }
    setInputVal(inputValue);
  }

  const debounceFunc = useMemo(() => {
    return debounce(updatefunc, 1000);
  }, [updatefunc]);

  useEffect(() => {
    setInputVal(
      moment().hours(timeValue.hour).minutes(timeValue.minute).format("HH:mm"),
    );
  }, [timeValue]);

  return (
    <StyledTimeSelect
      flowing={flowing}
      hoverable={hoverable}
      {...props}
      trigger={
        <Input
          onInput={(e) => handleOnInput(e.currentTarget.value)}
          value={inputVal}
          {...input}
        />
      }
    >
      <TimeSelectPopUp updatefunc={updatefunc} timeValue={timeValue} />
    </StyledTimeSelect>
  );
}

export default TimeSelect;
