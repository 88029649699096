import { RequesterContainer } from "@JS/nn4m-api-request";
import { useCallback } from "react";
import { Collection } from "../settings/types";
import { SpecialHoursResponse, DefaultResponse } from "./types/specialHours";
import { SpecialHourResponse, SpecialHour } from "../state-containers/Venues";

export function useSpecialHoursEndpoints() {
  const { requestFromCollection } = RequesterContainer.useContainer();

  const getSpecialHours = useCallback(
    (): Promise<SpecialHoursResponse> =>
      requestFromCollection(Collection.GetSpecialHours),
    [requestFromCollection],
  );

  const deleteSpecialHour = useCallback(
    (id: number): Promise<DefaultResponse> =>
      requestFromCollection(Collection.DeleteSpecialHour, {
        SPECIALHOUR_ID: id.toString(),
      }),
    [requestFromCollection],
  );

  const updateSpecialHour = useCallback(
    (specialHour: SpecialHourResponse): Promise<DefaultResponse> =>
      requestFromCollection(Collection.UpdateSpecialHour, {
        SPECIALHOUR_ID: specialHour.id!.toString(),
        SPECIALHOUR: JSON.stringify(specialHour),
      }),
    [requestFromCollection],
  );

  const createSpecialHour = useCallback(
    (specialHour: SpecialHour): Promise<DefaultResponse> =>
      requestFromCollection(Collection.CreateSpecialHour, {
        SPECIALHOUR: JSON.stringify(specialHour),
      }),
    [requestFromCollection],
  );

  return {
    getSpecialHours,
    deleteSpecialHour,
    updateSpecialHour,
    createSpecialHour,
  };
}
