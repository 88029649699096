import { SettingsContainer } from "@JS/nn4m-settings";
import React, { useEffect, useState } from "react";
import { SettingsKey } from "../../../settings/types";
import { VenueTablesContainer } from "../../../state-containers/VenueTables";

const TimeTillReEnable = ({ date }: { date: string }) => {
  const settings = SettingsContainer.useContainer();
  const { refreshVenueTables } = VenueTablesContainer.useContainer();

  const msTillReEnable =
    settings.integer(SettingsKey.ManageTablesSecondsTillReEnable) * 1000;
  const msRemaining = new Date(date).getTime() + msTillReEnable - Date.now();

  const [time, setTime] = useState(Math.floor(msRemaining / 1000));

  useEffect(() => {
    if (time > 0) {
      const handle = window.setTimeout(() => setTime(time - 1), 1000);
      return () => window.clearTimeout(handle);
    }
    return;
  }, [time, refreshVenueTables]);

  useEffect(() => {
    if (msRemaining > 0) {
      const handle = window.setTimeout(refreshVenueTables, msRemaining);
      return () => window.clearTimeout(handle);
    }
    return;
  }, [refreshVenueTables, msRemaining]);

  const uiSomethingWentWrong = settings.string(
    SettingsKey.ManageTablesSomethingWentWrong,
  );

  const uiReEnableText = settings.string(
    SettingsKey.ManageTablesReEnableText,
    undefined,
    { TIME_REMAINING: String(Math.floor(time / 60)) },
  );

  if (time <= 0) {
    // when time is up
    return <small>{uiSomethingWentWrong}</small>;
  }

  return <small>{uiReEnableText}</small>;
};

export default TimeTillReEnable;
