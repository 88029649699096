import styled from "styled-components/macro";
import { theme } from "../../../styles/theme";

export const StyledMenuItem = styled.div<{
  active?: boolean;
  visible?: boolean;
  open?: boolean;
}>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  padding: ${theme.layout.small} ${theme.layout.medium};
  color: ${theme.colour.white};
  min-height: 50px;
  height: fit-content;
  width: 100%;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: #4b5561;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${theme.fontSize.small};
    color: ${theme.colour.white};
    font-weight: ${(props) =>
      props.active ? theme.fontWeight.medium : theme.fontWeight.light};
  }

  .imageContainer,
  .iconContainer {
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    height: 30px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .imageContainer {
    background-color: ${theme.colour.offWhite};
    border-radius: 30px;
  }

  .dropdownIcon {
    margin-left: auto;
    filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(216deg)
      brightness(107%) contrast(107%);
    img {
      transform: ${(props) => (props.open ? "rotate(180deg)" : null)};
      transform-origin: center;
    }
  }
`;

StyledMenuItem.defaultProps = {
  visible: true,
  active: false,
};

export default StyledMenuItem;
