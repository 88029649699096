import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const StyledAddSpecialHours = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > :first-child {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${theme.layout.medium};

    h3 {
      font-weight: ${theme.fontWeight.bold};
    }

    > div {
      display: flex;
      gap: ${theme.layout.medium};
    }
  }

  .content {
    width: 100%;
    display: flex;
    max-width: 1440px;
    gap: ${theme.layout.medium};

    > * {
      width: 50%;
    }

    > :first-child {
      height: auto;

      p {
        margin-bottom: ${theme.layout.small};
      }

      .top {
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme.layout.large};

        input {
          margin-bottom: ${theme.layout.medium};
        }
      }

      .bottom {
        > :first-child {
          hr {
            display: none;
          }
        }

        .specialOpeningTime {
          margin-bottom: ${theme.layout.medium};

          hr {
            width: calc(100% + calc(${theme.layout.medium} * 2));
            margin-left: -${theme.layout.medium};
            border: none;
            border-top: 1px ${theme.colour.lightGrey} solid;
            margin-bottom: ${theme.layout.medium};
          }

          .dateDetails {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin: ${theme.layout.medium} 0;

            > div {
              p {
                white-space: nowrap;
              }

              input {
                width: 100px;
              }
            }
          }

          .isClosed {
            display: flex;
            gap: ${theme.layout.small};
            width: fit-content;
            cursor: pointer;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: ${theme.layout.medium};

      > :last-child {
        > div {
          display: flex;

          h4 {
            margin: 0 ${theme.layout.small} ${theme.layout.medium} 0;
          }
        }
      }
    }
  }
`;
