import React from "react";
import Button from "../../button/ButtonStyles";
import { OverlayMessageCardStyles } from "./OverlayMessageCardStyles";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../../../settings/types";
import { Link } from "react-router-dom";

const OverlayMessageCard = () => {
  const settings = SettingsContainer.useContainer();
  const addOverlayTitle = settings.string(
    SettingsKey.ManageVenuesAddOverlayTitle,
  );
  const noOverlay = settings.string(
    SettingsKey.ManageVenuesAddOverlayNoOverlay,
  );
  const addOverlayButton = settings.string(
    SettingsKey.ManageVenuesAddOverlayButton,
  );

  return (
    <OverlayMessageCardStyles>
      <h3>{addOverlayTitle}</h3>
      <i>{noOverlay}</i>
      <Link to="/manage-venues/overlay-message">
        <Button>{addOverlayButton}</Button>
      </Link>
    </OverlayMessageCardStyles>
  );
};

export default OverlayMessageCard;
