import React, { useEffect, useMemo } from "react";
import Nav from "../../../components/nav/Nav";
import { StyledSpecialHours } from "./SpecialHoursStyles";
import { SettingsKey } from "../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";
import { HistoryContainer } from "../../../state-containers/History";
import NoSpecialHours from "./segments/NoSpecialHours";
import SpecialHoursList from "./segments/SpecialHoursList";
import { toast } from "../../../components/toast/Toast";
import { DataLoader } from "@JS/nn4m-api-request";

// State
import { VenueContainer } from "../../../state-containers/Venues";

function SpecialHours() {
  const history = HistoryContainer.useContainer();
  const settings = SettingsContainer.useContainer();
  const { selectedVenue, specialHours, fetchSpecialHours } =
    VenueContainer.useContainer();

  useEffect(() => {
    if (!specialHours.isReady) {
      fetchSpecialHours();
    }
  }, [fetchSpecialHours, specialHours.isReady]);

  useEffect(() => {
    if (!selectedVenue.data) {
      toast.error(
        settings.string(SettingsKey.SpecialHoursNoSelectedVenueToast),
        {
          position: "bottom-center",
        },
      );
      history.push("/manage-venues");
    } else {
      toast.dismiss();
    }
  }, [history, selectedVenue, settings]);

  const specialHoursData = useMemo(() => {
    if (specialHours.isReady) {
      return specialHours.data;
    }
    return undefined;
  }, [specialHours]);

  const venueSpecialHours = useMemo(() => {
    // There are special hours available and there is a selected venue
    if (
      specialHoursData &&
      specialHoursData?.specialHours.length > 0 &&
      selectedVenue.data
    ) {
      return specialHoursData.specialHours.filter((specialHour) => {
        return specialHour.venueId === selectedVenue.data!.venueId;
      });
    }
    return undefined;
  }, [selectedVenue.data, specialHoursData]);

  if (specialHours.data) {
    specialHours.data.specialHours.map((specialHour) =>
      specialHour.dates.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      ),
    );
  }

  return (
    <Nav
      hasSearchButton
      back={() => history.goBack("/manage-venues")}
      title={settings.string(SettingsKey.SpecialHoursPageTitle)}
    >
      <StyledSpecialHours>
        <DataLoader data={[specialHours]}>
          {venueSpecialHours && venueSpecialHours.length > 0 ? (
            <SpecialHoursList specialHours={venueSpecialHours} />
          ) : (
            <NoSpecialHours />
          )}
        </DataLoader>
      </StyledSpecialHours>
    </Nav>
  );
}

export default SpecialHours;
