import React from "react";
import { StyledCard } from "./CardStyles";
import { Link } from "react-router-dom";
import Button from "../button/ButtonStyles";
import Props from "./CardTypes";

function Card({ iconURL, title, buttonText, link, lastUpdated }: Props) {
  return (
    <StyledCard role="gridcell">
      <div className="iconContainer">
        <img src={iconURL} alt="cardIcon" />
      </div>
      <h4>{title}</h4>
      {lastUpdated && <p>Last updated: {lastUpdated}</p>}
      <Link to={link}>
        <Button>{buttonText}</Button>
      </Link>
    </StyledCard>
  );
}

export default Card;
