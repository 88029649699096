import type { SettingsKey } from "../settings/types";
import { useCallback } from "react";
import { SettingsContainer } from "@JS/nn4m-settings";
import { RCodeContainer } from "../state-containers/RCode";

function useClientSettings() {
  const settings = SettingsContainer.useContainer();
  const { selectedRCode } = RCodeContainer.useContainer();

  const getClientSetting = useCallback(
    <T extends unknown>(
      settingsKey: SettingsKey,
      defaultValue?: Record<string, T>,
      substitutions?: Record<string, string>,
    ) =>
      settings.json<Record<string, T>>(
        settingsKey,
        defaultValue,
        substitutions,
      )[selectedRCode],
    [selectedRCode, settings],
  );

  return { getClientSetting };
}

export { useClientSettings };
