import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${theme.fontFamily}, sans-serif;
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.medium};
    background-color: ${theme.colour.offWhite};
  }

  .modals.dimmer .ui.modal,
  .modals.dimmer .ui.scrolling.modal {
    margin: auto !important;

    & > .header,
    & > .actions {
      padding: ${theme.layout.medium} !important;
    }

    & > .content {
      padding: 0 !important;
    }
  }
  
  #root {
    height: 100%;
  }

  main {
    width: 100%;
    height: 100%;
  }

  h1, h2, h3, h4, p { 
    margin: 0;
    color: ${theme.colour.black};
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: ${theme.fontSize.title}; // 40px
    font-weight: ${theme.fontWeight.semiBold};
  }

  h2 {
    font-size: 1.43rem;
  }

  h3 {
    font-size: ${theme.fontSize.large}; // 28px
    font-weight: ${theme.fontWeight.medium};
  }

  h4 {
    font-size: ${theme.fontSize.medium}; // 16px
    font-weight: ${theme.fontWeight.medium};
  }

  p {
    font-size: ${theme.fontSize.small}; // 14px;
  }

  small {
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontWeight.light};
  }


  // Semantic component styling

  .ui.modal {
    width: fit-content;
    border-radius: 0px;
  }

  .ui.selection.dropdown {
    border-radius: ${theme.borderRadius.medium};
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.normal};
    color: ${theme.colour.black};
    height: 40px;
    width: 100%;
    outline: none;
    border: 1px ${theme.colour.lightGrey} solid;
    padding: 0 ${theme.layout.medium};
    background: ${theme.colour.extraLightGrey};
    display: flex;
    align-items: center;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.active {
      border-radius: ${theme.borderRadius.medium} !important;
    }

    .menu {
      border-radius: ${theme.borderRadius.medium};
      max-width: 250px;
      width: 100%;
      min-width: unset;
      right: 0;
      left: auto;
      top: 40px;

      .item {
        padding: 0 ${theme.layout.medium};
        height: 40px;
        background: ${theme.colour.white};
      }
    }
  }
`;
