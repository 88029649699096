import React from "react";
import StyledMenuItem from "./MenuItemStyles";
import { Link } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
  open?: boolean;
  visible?: boolean;
  link?: string;
  externalLink?: string;
};

function MenuItem({ externalLink, link, children, ...props }: Props) {
  return (
    <>
      {externalLink ? (
        <a role="button" href={externalLink}>
          <StyledMenuItem {...props}>{children}</StyledMenuItem>
        </a>
      ) : link ? (
        <Link role="button" to={link}>
          <StyledMenuItem {...props}>{children}</StyledMenuItem>
        </Link>
      ) : (
        <StyledMenuItem role="button" {...props}>
          {children}
        </StyledMenuItem>
      )}
    </>
  );
}

export default MenuItem;
