import React, { useEffect } from "react";
import { HistoryContainer } from "../../state-containers/History";

// Styling
import { StyledMenuCombining, StyledModalContent } from "./MenuCombiningStyles";

// Components
import Container from "../../components/container/ContainerStyles";
import Button from "../../components/button/ButtonStyles";
import Rule from "./rule/Rule";
import Nav from "../../components/nav/Nav";
import { toast } from "../../components/toast/Toast";
import Modal from "../../components/modal/Modal";
import { DataLoader } from "@JS/nn4m-api-request";

// State
import { RuleContainer } from "./rule/RuleState";
import useMenuCombining from "./MenuCombiningState";
import { MenuCombiningContainer } from "../../state-containers/MenuCombining";

// API
import { useCombinedMenusEndpoints } from "../../api/combinedMenus";
import { SettingsKey } from "../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

function MenuCombining() {
  const { newRule, setNewRule, isModalActive, setIsModalActive } =
    useMenuCombining();
  const history = HistoryContainer.useContainer();
  const { data, fetchData, editedRules } =
    MenuCombiningContainer.useContainer();
  const { publishCombinedMenus, updateCombinedMenu } =
    useCombinedMenusEndpoints();
  const settings = SettingsContainer.useContainer();

  useEffect(() => {
    if (!data.data) {
      fetchData();
    }
  }, [fetchData, data.data]);

  function handleBackButton() {
    if (editedRules.length > 0) {
      setIsModalActive(true);
    } else {
      history.goBack("/");
    }
  }

  function handleMakeLive() {
    publishCombinedMenus()
      .then(() => {
        toast.success(
          settings.string(SettingsKey.MenuCombiningPublishSuccess),
          {
            position: "bottom-center",
          },
        );
      })
      .catch(() => {
        toast.error(settings.string(SettingsKey.MenuCombiningPublishFail), {
          position: "bottom-center",
        });
      });
  }

  function saveAllChanges() {
    Promise.all(
      editedRules.map((editedRule) => {
        return updateCombinedMenu(editedRule.id.toString(), editedRule);
      }),
    )
      .then(() => {
        toast.success(
          settings.string(SettingsKey.MenuCombiningSaveAllChangesSuccess),
          {
            position: "bottom-center",
          },
        );
        history.goBack("/");
      })
      .catch(() => {
        toast.error(
          settings.string(SettingsKey.MenuCombiningSaveAllChangesFail),
          {
            position: "bottom-center",
          },
        );
      });
  }

  const sortedRules =
    data?.data &&
    data!.data!.rules.sort(
      (a, b) => Date.parse(b.createdAt.date) - Date.parse(a.createdAt.date),
    );

  return (
    <Nav
      title={settings.string(SettingsKey.MenuCombiningPageTitle)}
      back={handleBackButton}
    >
      <StyledMenuCombining>
        <div className="pageHeading">
          <Container>
            <div className="pageTitle">
              <div>
                <h2>{settings.string(SettingsKey.MenuCombiningPageTitle)}</h2>
                <h3>
                  {settings.string(SettingsKey.MenuCombiningPageDescription)}
                </h3>
              </div>
              <Button
                onClick={handleMakeLive}
                disabled={editedRules.length !== 0 || newRule}
                variant="destructive"
              >
                {settings.string(SettingsKey.MenuCombiningMakeLive)}
              </Button>
            </div>
          </Container>
          <div className="subHeading">
            <Button disabled={newRule} onClick={() => setNewRule(true)}>
              {settings.string(SettingsKey.MenuCombiningNewRule)}
            </Button>
          </div>
        </div>
        <div className="content">
          {newRule && (
            <RuleContainer.Provider>
              <Rule isNew cancelNewRule={() => setNewRule(false)} />
            </RuleContainer.Provider>
          )}
          <DataLoader data={[data]}>
            {data?.data &&
              sortedRules!.map((rule, index: number) => {
                return (
                  <RuleContainer.Provider
                    initialState={{ ...rule }}
                    key={index}
                  >
                    <Rule />
                  </RuleContainer.Provider>
                );
              })}
          </DataLoader>
        </div>
      </StyledMenuCombining>
      <Modal
        open={isModalActive}
        onClose={() => setIsModalActive(false)}
        title={settings.string(SettingsKey.MenuCombiningLeaveModalTitle)}
        dimmer
        icon="alert"
        actions={
          <>
            <Button variant="cancel" onClick={() => history.goBack("/")}>
              {settings.string(SettingsKey.MenuCombiningLeaveWithoutSaving)}
            </Button>
            <Button variant="cta" onClick={saveAllChanges}>
              {settings.string(SettingsKey.MenuCombiningSaveAllChanges)}
            </Button>
          </>
        }
      >
        <StyledModalContent>
          {settings.string(SettingsKey.MenuCombiningLeaveModalDescription)}
        </StyledModalContent>
      </Modal>
    </Nav>
  );
}

export default MenuCombining;
