import { CombinedMenuRule } from "../MenuCombiningTypes";

export const hasEmptyValues = (rule: CombinedMenuRule) => {
  return (
    rule.childMenu.name !== "" &&
    rule.childMenu.id !== undefined &&
    rule.childMenu.id !== null &&
    rule.parentMenu.name !== "" &&
    rule.parentMenu.id !== undefined &&
    rule.parentMenu.id !== null &&
    rule.mergeMethod !== ""
  );
};
