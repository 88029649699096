import { useCallback, useState } from "react";
import { RCodeContainer } from "../state-containers/RCode";

function useClientState<T>(initial: T) {
  const { selectedRCode, availableRCodes } = RCodeContainer.useContainer();
  const [state, __setState] = useState<Record<string, T>>(
    Object.fromEntries(availableRCodes.map((rc) => [rc, initial])),
  );

  const setState: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (value) =>
      __setState((prev) => ({
        ...prev,
        [selectedRCode]:
          value instanceof Function ? value(prev[selectedRCode]) : value,
      })),
    [selectedRCode],
  );

  return [state?.[selectedRCode], setState] as [T, typeof setState];
}

export { useClientState };
