import { useState } from "react";
import { createContainer } from "unstated-next";
import { SpecialHourResponse, VenueContainer } from "./Venues";
import moment from "moment-timezone";

function useAddSpecialHoursState() {
  const { selectedVenueId } = VenueContainer.useContainer();
  // Venue id will always be defined if on this page
  // id will be set by fetch or when sending to BE
  const defaultSpecialHours: SpecialHourResponse = {
    id: undefined,
    dates: [
      {
        closed: false,
        closingHour: "23:00:00",
        date: moment(new Date()).format("YYYY-MM-DD"),
        label: "",
        openingHour: "08:00:00",
      },
    ],
    label: "",
    venueId: selectedVenueId!,
  };

  const [addedOpeningTimes, setAddedOpeningTimes] =
    useState<SpecialHourResponse>(defaultSpecialHours);

  return {
    addedOpeningTimes,
    setAddedOpeningTimes,
    defaultSpecialHours,
  };
}

export const AddSpecialHoursStateContainer = createContainer(
  useAddSpecialHoursState,
);
