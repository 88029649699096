import React from "react";
import moment from "moment-timezone";
import { Input } from "semantic-ui-react";

type TimeSliderProps = {
  minTime: number;
  maxTime: number;
  hours?: boolean;
  value: number;
  updatefunc: (val: number) => void;
};

function TimeSlider({
  value,
  hours = false,
  minTime,
  maxTime,
  updatefunc,
}: TimeSliderProps) {
  return (
    <div className="timeSlider">
      <div className="minTime time">
        <p>{hours ? moment(minTime, "HH").format("ha") : minTime}</p>
      </div>
      <Input
        onInput={(val) => updatefunc(val.currentTarget.value)}
        min={minTime}
        max={maxTime}
        value={value}
        step={1}
        className="slider"
        type="range"
      />
      <div className="maxTime time">
        <p>{hours ? moment(maxTime, "HH").format("ha") : maxTime}</p>
      </div>
    </div>
  );
}

export default TimeSlider;
