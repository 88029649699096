import type { PropsWithChildren } from "react";
import React from "react";
import { KeycloakContainer } from "@JS/nn4m-keycloak-auth";
import { RequesterContainer } from "@JS/nn4m-api-request";
import { SettingsContainer } from "@JS/nn4m-settings";

function RequestProvider({ children }: PropsWithChildren<{}>) {
  const { isLoggedIn, getToken, updateToken } =
    KeycloakContainer.useContainer();

  const settings = SettingsContainer.useContainer();

  return settings.hasSettings() && isLoggedIn() ? (
    <RequesterContainer.Provider initialState={{ getToken, updateToken }}>
      {children}
    </RequesterContainer.Provider>
  ) : null;
}

export { RequestProvider };
