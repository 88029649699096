import {
  errorData,
  fetchingData,
  noData,
  readyData,
  RequestedData,
} from "@JS/nn4m-api-request";
import { useCallback } from "react";
import { useClientState } from "./useClientState";

interface RequestedDataCallbacks<RawData, Data> {
  transformData?: (rawData: RawData) => Data;
  successCallback?: (result: RawData) => void;
  errorCallback?: (error: string) => void;
}

type RequestFunction<T, R extends unknown[]> = (...args: R) => Promise<T>;

export function useClientRequestedData<
  Args extends unknown[],
  RawData,
  Data = RawData,
  R extends RequestFunction<RawData, Args> = RequestFunction<RawData, Args>,
>(request: R, callbacks: RequestedDataCallbacks<RawData, Data> = {}) {
  const [data, setData] = useClientState<RequestedData<Data>>(noData());

  const { transformData, successCallback, errorCallback } = callbacks;

  const fetchData = useCallback(
    (...args: Args) => {
      setData(fetchingData());
      return request(...args)
        .then((result) => {
          setData(readyData(transformData ? transformData(result) : result));
          if (successCallback) {
            successCallback(result);
          }
        })
        .catch((error) => {
          setData(errorData(error.message));
          if (errorCallback) {
            errorCallback(error.message);
          }
        });
    },
    [errorCallback, request, setData, successCallback, transformData],
  );

  return {
    data,
    setData,
    fetchData,
  };
}
