import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const StyledSpecialHours = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .specialHours {
    margin-top: ${theme.layout.large};
    display: flex;
    flex-direction: column;
    align-items: center;

    .specialHour {
      padding-bottom: ${theme.layout.small};
      border-bottom: 1px ${theme.colour.lightGrey} solid;
      margin-bottom: ${theme.layout.medium};
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dates {
        margin: ${theme.layout.small} 0;
        font-weight: ${theme.fontWeight.light};
      }

      > :last-child {
        display: flex;
        gap: ${theme.layout.medium};

        > img {
          max-width: 18px;
          max-height: 18px;
          cursor: pointer;
        }
      }
    }

    button {
      margin: 0 auto;
    }
  }

  .containerInner {
    .containerTitle {
      margin-bottom: ${theme.layout.medium};
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.layout.small};
      padding-right: ${theme.layout.xLarge};

      > img {
        width: 80px;
        height: auto;
      }

      > div {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        h1 {
          color: ${theme.colour.black};
          margin-bottom: ${theme.layout.xSmall};
        }

        h3 {
          font-weight: ${theme.fontWeight.light};
        }
      }
    }

    button {
      width: 100%;
    }
  }

  @media only screen and (min-width: 600px) {
    .containerInner {
      .containerTitle {
        flex-direction: row;

        > div {
          text-align: start;
          align-items: flex-start;
        }
      }
    }
  }
`;
