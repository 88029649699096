import { useCallback } from "react";
import { RequesterContainer } from "@JS/nn4m-api-request";
import { SettingsKey, Collection } from "../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

type TimeZoneResponse = {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
};

function useTimeZone() {
  const { requestFromCollection } = RequesterContainer.useContainer();
  const settings = SettingsContainer.useContainer();

  const getTimeZone = useCallback(
    async (venue): Promise<TimeZoneResponse> => {
      const googleApiKey = settings.string(SettingsKey.GoogleMapsApiKey);

      return requestFromCollection(Collection.GetTimeZone, {
        LATITUDE: venue.lat,
        LONGITUDE: venue.long,
        TIMESTAMP: (Date.now() / 1000).toString(),
        GOOGLE_API_KEY: googleApiKey,
      });
    },
    [requestFromCollection, settings],
  );

  return {
    getTimeZone,
  };
}

export { useTimeZone };
