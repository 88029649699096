import React from "react";
import { StyledVenueInfo } from "./VenueInfoCardStyles";
import { Label } from "semantic-ui-react";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../../../settings/types";
import { useClientSettings } from "../../../helpers/clientSettings";
import { VenueDetails } from "../../../api/types/venueDetails";
import { hasMultipleAddressLines } from "../../../api/types/guards";

const VenueInfoCard = (v: VenueDetails) => {
  const { getClientSetting } = useClientSettings();
  const settings = SettingsContainer.useContainer();

  const pubID = settings.string(SettingsKey.PubId, undefined, {
    ID: String(v.iOrderId),
  });
  const venueID = settings.string(SettingsKey.VenueId, undefined, {
    ID: String(v.venueId),
  });
  const salesAreaID = settings.string(SettingsKey.SalesAreaId, undefined, {
    ID: String(v.salesAreaId),
  });
  const labels = getClientSetting<{ key: string; text: string }[]>(
    SettingsKey.ManageVenuesShownInfoLabels,
  );
  const address = settings.string(SettingsKey.ManageVenuesAddress);

  return (
    <StyledVenueInfo>
      <div className="general">
        <h2>{v.name}</h2>

        <span>
          <span>{pubID}</span>
          <span>{venueID}</span>
          <span>{salesAreaID}</span>
        </span>

        <span>
          {labels?.map(({ key, text }) => (
            <Label key={key}>
              {text} {String(v[key])}
            </Label>
          ))}
        </span>
      </div>

      <div className="address">
        <h3>{address}</h3>

        <address>
          {(hasMultipleAddressLines(v)
            ? [v.line1, v.line2, v.town, v.country, v.postcode]
            : [v.line1, v.town, v.country, v.postcode]
          ).map((line, i) => (
            <span key={i}>{line}</span>
          ))}
        </address>
      </div>
    </StyledVenueInfo>
  );
};

export default VenueInfoCard;
