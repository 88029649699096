import { createContainer } from "unstated-next";
import { useClientRequestedData } from "../helpers/useClientRequestedData";
import {
  useVenueTablesEndpoints,
  VenueTablesResponse,
} from "../api/venueTables";
import { VenueTable, VenueTableChanges } from "../api/types/venueTables";
import { VenueContainer } from "./Venues";
import { useCallback } from "react";

function useVenueTables() {
  const { selectedVenueId } = VenueContainer.useContainer();
  const { getVenueTables, updateVenueTables } = useVenueTablesEndpoints();

  // get table data of selected venue once selected
  const { data: venueTables, fetchData: fetchVenueTables } =
    useClientRequestedData<[number], VenueTablesResponse | null>(
      getVenueTables,
    );

  async function toggleVenueTables(tables: VenueTable[]) {
    const changes: VenueTableChanges = { enable: [], disable: [] };

    if (selectedVenueId) {
      for (const { active, table_number } of tables) {
        const change = active ? changes.disable : changes.enable;
        change.push(table_number);
      }

      await updateVenueTables(selectedVenueId, changes.enable, changes.disable);
    }
    return changes;
  }

  const refreshVenueTables = useCallback(async () => {
    if (selectedVenueId) {
      await fetchVenueTables(selectedVenueId);
    }
  }, [fetchVenueTables, selectedVenueId]);

  return {
    venueTables,
    toggleVenueTables,
    refreshVenueTables,
  };
}

export const VenueTablesContainer = createContainer(useVenueTables);
