export const icons = {
  profile: require("./icn_profile.png"),
  home_inactive: require("./icn_home_inactive.svg"),
  home: require("./icn_home_active.svg"),
  accordion_down: require("./icn_accordion_down_grey.svg"),
  logo: require("./icn_nn4m_white.svg"),
  search: require("./icn_search.svg"),
  alert: require("./icn_toast_alert.svg"),
  back: require("./icn_back.svg"),
  telephone: require("./icn_telephone.svg"),
  clock: require("./icn_clock.svg"),
  specialHours: require("./icn_specialhours.svg"),
  selection_on: require("./icn_selection_on.svg"),
  bin: require("./icn_bin.svg"),
  edit: require("./icn_edit.svg"),
  nav_back: require("./icn-nav-back.svg"),
  nav_search: require("./icn-nav-search.svg"),
};
