import type { GoogleMapProps, MarkerProps } from "react-google-maps";
import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { SettingsKey } from "../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

interface Props {
  center?: { lat: number; lng: number };
  zoom?: number;
  markers?: (MarkerProps & { key: number | string })[];
}

function Map({ center = { lat: 0, lng: 0 }, zoom = 8, markers = [] }: Props) {
  const settings = SettingsContainer.useContainer();
  const API_KEY = settings.string(SettingsKey.GoogleMapsApiKey);

  const DEFAULT_ELEMENT = (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "200px",
        minWidth: "200px",
      }}
    />
  );

  const InternalMap = withScriptjs(
    withGoogleMap((_props: GoogleMapProps) => (
      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={center}
        options={{ disableDefaultUI: true }}
      >
        {markers.map((p) => (
          <Marker {...p} />
        ))}
      </GoogleMap>
    )),
  );

  return (
    <InternalMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={DEFAULT_ELEMENT}
      containerElement={DEFAULT_ELEMENT}
      mapElement={DEFAULT_ELEMENT}
    />
  );
}

export default Map;
