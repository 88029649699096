import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import { NotAllowed } from "@JS/nn4m-keycloak-auth";

export const StyledNotAllowed = styled(NotAllowed)`
  background: ${theme.primaryColour};
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: ${theme.colour.white};
`;
