import type { OpeningTime } from "../../../../components/timeTable/TimeTableTypes";
import moment from "moment-timezone";

export const isOpened = (openingTimes: OpeningTime[], timeZone: string) => {
  const today = openingTimes.find((time) => {
    return time.label === moment().format("dddd");
  });
  const closingTimeHour = today?.times.split(" ").slice(2).join(" "); // 12
  const closingTimeTimeOfDay = closingTimeHour?.substr(
    closingTimeHour.length - 2,
    2,
  ); // am
  const closingTimeHourFormatted =
    closingTimeTimeOfDay === "am"
      ? closingTimeHour?.substr(0, 2)
      : +closingTimeHour!.substr(0, 2) + 12; // 12 || 24 if pm
  const closingTimeMinutes = today?.times
    .split(":")
    .slice(2)
    .join("")
    .substr(0, 2);

  const closingTime = moment()
    .tz(timeZone)
    .hour(+closingTimeHourFormatted!)
    .minutes(+closingTimeMinutes!);
  return closingTime.isAfter(moment());
};
