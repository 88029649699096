import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import { Modal } from "semantic-ui-react";

export const StyledModal = styled(Modal)`
  border-radius: 6px !important;

  @media only screen and (min-width: 768px) {
    min-width: 550px;
  }
`;

export const StyledHeader = styled(Modal.Header)`
  background-color: ${theme.primaryColour};
  display: flex !important;
  align-items: center;
  gap: ${theme.layout.medium};
  padding: ${theme.layout.medium};

  * {
    color: ${theme.colour.white};
  }
`;

export const StyledContent = styled(Modal.Content)`
  h3 {
    font-weight: ${theme.fontWeight.normal};
    white-space: break-spaces;
  }
`;

export const StyledActions = styled(Modal.Actions)`
  padding: ${theme.layout.medium};
  border-top: 1px ${theme.colour.lightGrey} solid;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.layout.medium};
`;
