import styled from "styled-components/macro";
import { theme } from "../../../styles/theme";
import selection_on from "../../../assets/icons/icn-selection-on.svg";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: ${theme.layout.medium};
  font-size: ${theme.fontSize.medium};

  .dropdown {
    position: absolute;
    max-width: 100%;
    width: calc(100% - ${theme.layout.medium} * 2) !important;
    z-index: -1;

    visibility: hidden;
    > :last-child {
      visibility: visible;
    }

    [role="option"]:hover {
      background-color: ${theme.colour.lightGrey} !important;
    }

    .active {
      font-weight: unset !important;
      position: relative;
      ::after {
        content: "";
        /* note using \${icons.selection_on} with require( ) below 
        causes styled-components to throw in a jest environment (?????) */
        background-image: url("${selection_on}");
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        right: 10px;
        position: absolute;
      }
    }
  }
`;
