import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

interface Props {
  columns?: number;
  gap?: string;
}

const Grid = styled.div<Props>`
  display: grid;
  max-width: 1000px;
  margin: 0 auto;
  padding: ${theme.layout.medium};
  gap: ${(props) => props.gap};
  grid-template-columns: repeat(1, auto);

  @media only screen and (min-width: 600px) {
    grid-template-columns: ${(props) => `repeat(${props.columns}, auto)`};
  }
`;

Grid.defaultProps = {
  columns: 2,
  gap: theme.layout.small,
};

export default Grid;
