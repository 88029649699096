import styled from "styled-components/macro";
import { theme } from "../../../styles/theme";

export const StyledRuleCollapsed = styled.div`
  > :first-child {
    margin: 0;
    padding: 0;

    > div {
      display: flex;
      align-items: flex-start;

      > * {
        > * {
          height: 30px;
          display: flex;
          align-items: center;
        }
      }

      .left {
        margin-right: ${theme.layout.small};

        h3 {
          justify-content: flex-end;
        }

        > :first-child {
          margin-bottom: ${theme.layout.medium};
        }
      }

      .dividers {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.medium};

        .divider {
          border-right: 1px ${theme.colour.lightGrey} solid;
        }
      }

      .right {
        margin-left: 10px;
        text-align: left;

        h3 {
          color: ${theme.colour.black};
        }

        > :first-child {
          margin-bottom: ${theme.layout.medium};
        }
      }
    }
  }
`;
