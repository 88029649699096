import styled from "styled-components";
import { theme } from "../../styles/theme";

export const StyledLabel = styled.div`
  display: flex;
  height: min-content;
  width: min-content;
  align-items: center;
  border-radius: ${theme.borderRadius.small};
  padding: 0 ${theme.layout.xSmall};
  background-color: ${theme.colour.lightGrey};
  font-size: ${theme.fontSize.xSmall};
  color: ${theme.colour.grey};
  font-weight: ${theme.fontWeight.normal};
  white-space: nowrap;
`;
