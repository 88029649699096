import produce from "immer";
import {
  SpecialHourResponse,
  SpecialHourDate,
} from "../../../../state-containers/Venues";
import { AddSpecialHoursStateContainer } from "../../../../state-containers/AddSpecialHour";

export function useAddSpecialHoursState() {
  const { setAddedOpeningTimes, addedOpeningTimes, defaultSpecialHours } =
    AddSpecialHoursStateContainer.useContainer();

  function addNewOpeningTime() {
    setAddedOpeningTimes((prevState) => {
      return {
        ...prevState,
        dates: [...prevState.dates, defaultSpecialHours.dates[0]],
      };
    });
  }

  function findIndex(addedOpeningTime) {
    return addedOpeningTimes.dates.findIndex(
      (date) => date === addedOpeningTime,
    );
  }

  function removeSpecialHour(target: SpecialHourDate) {
    setAddedOpeningTimes((prevState) => {
      return produce(prevState, (draftState: SpecialHourResponse) => {
        draftState.dates.splice(findIndex(target), 1);
      });
    });
  }

  function editSpecialHour<
    Key extends keyof SpecialHourDate,
    Value extends SpecialHourDate[Key],
  >(target: SpecialHourDate, property: Key, value: Value) {
    setAddedOpeningTimes((prevState) => {
      return produce(prevState, (draftState: SpecialHourResponse) => {
        draftState.dates[findIndex(target)][property] = value;
      });
    });
  }

  function handleSpecialHourIsClosed(target: SpecialHourDate) {
    setAddedOpeningTimes((prevState) => {
      return produce(prevState, (draftState) => {
        draftState.dates[findIndex(target)].closed =
          !prevState.dates[findIndex(target)].closed;
      });
    });
  }

  return {
    addNewOpeningTime,
    editSpecialHour,
    removeSpecialHour,
    handleSpecialHourIsClosed,
    findIndex,
  };
}
