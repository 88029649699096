import styled from "styled-components/macro";
import { Dropdown } from "semantic-ui-react";
import { theme } from "../../styles/theme";

export const StyledRCodeSelector = styled(Dropdown)`
  &.ui.dropdown {
    color: white;
    margin: 10px 20px 10px 44px;
    padding-left: 16px;
    width: 136px;
    display: flex;
    justify-content: space-between;

    .menu {
      margin-top: 30px;
      width: 136px;
      border: 1px solid white;
      border-radius: ${theme.borderRadius.small};
      background: ${theme.primaryColour};

      .item {
        color: ${theme.colour.white};
        &:first-child {
          border-top-left-radius: ${theme.borderRadius.small};
          border-top-right-radius: ${theme.borderRadius.small};
        }

        &:last-child {
          box-shadow: none;
          border-bottom-left-radius: ${theme.borderRadius.small};
          border-bottom-right-radius: ${theme.borderRadius.small};
        }

        &:hover {
          background: ${theme.primaryColourDark};
        }
      }
    }
  }
`;
