import { RequesterContainer } from "@JS/nn4m-api-request";
import { useCallback } from "react";
import { Collection, SettingsKey } from "../settings/types";
import { VenuesResponse } from "./types/venues";
import { useClientSettings } from "../helpers/clientSettings";
import { VenueDetails } from "./types/venueDetails";

export function useVenuesEndpoints() {
  const { requestFromCollection } = RequesterContainer.useContainer();
  const { getClientSetting } = useClientSettings();

  const getVenues = useCallback(
    (): Promise<VenuesResponse> => requestFromCollection(Collection.GetVenues),
    [requestFromCollection],
  );

  const getVenueDetails = useCallback(
    (venueId: number): Promise<VenueDetails> =>
      requestFromCollection(Collection.GetVenueDetails, {
        AWS_PUBLIC_BUCKET: getClientSetting(SettingsKey.AwsPublicBucket),
        VENUE_ID: venueId.toString(),
      }),
    [getClientSetting, requestFromCollection],
  );

  return { getVenues, getVenueDetails };
}
