import { createContainer } from "unstated-next";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const stamp = "__initial__";

function useSmartHistory() {
  const history = useHistory();

  useEffect(() => {
    history.replace({ state: stamp });
    // this should trigger only once, ideally deps would be "[]"
    // but eslint(react-hooks/exhaustive-deps) disagrees

    // as long as <BrowserRouter /> does not rerender the history
    // object doesn't update therefore this should not be an issue
  }, [history]);

  function goBack(pathname = "/") {
    if (history.location.state === stamp) {
      const current = history.location.pathname;
      history.replace({ pathname, state: stamp });
      history.push(current);
    }
    history.goBack();
  }

  return {
    ...history,
    goBack,
  };
}

export const HistoryContainer = createContainer(useSmartHistory);
