import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

const phone = {
  top: "32px",
  right: "14px",
  bottom: "28px",
  left: "12px",
};

const Wrapper = styled.div`
  max-width: 400px;
  position: relative;
  margin: 0 auto;

  > img {
    pointer-events: none;
  }

  #phone-border {
    z-index: 20;
  }

  #status-bar {
    position: absolute;
    top: 0;
    padding: ${phone.top} ${phone.right} ${phone.bottom} ${phone.left};
    z-index: 15;
  }

  .timetable {
    padding: 0 ${theme.layout.medium} ${theme.layout.medium}
      ${theme.layout.medium};
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  margin: 56px ${phone.right} 0 ${phone.left};

  width: calc(100% - ${phone.right} - ${phone.left});
  height: calc(100% - 141px);
  background-color: white;

  display: flex;
  flex-direction: column;

  #top-bar {
    font-family: "Avalon-Book";
    padding: ${theme.layout.small} ${theme.layout.medium};
    display: flex;
    align-items: center;

    background-color: white;

    > :first-child {
      margin-right: 25px;
    }

    > :last-child {
      margin-left: auto;
    }
  }

  #venue-image {
    width: 100%;
    height: 190px;
    object-fit: cover;
  }

  #venue-image-text {
    font-family: "Avalon-Book";
    text-align: center;
    font-size: ${theme.fontSize.xSmall};
    font-weight: ${theme.fontWeight.light};
  }

  #venue-name {
    font-family: "Avalon-Book";
    padding: ${theme.layout.small} ${theme.layout.medium} 0;

    > span {
      float: right;
      font-weight: ${theme.fontWeight.light};
    }
  }

  #venue-address {
    font-family: "Avalon-Book";
    padding: ${theme.layout.small} ${theme.layout.medium};
    font-weight: ${theme.fontWeight.light};
  }

  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Actions = styled.div`
  padding: ${theme.layout.small} ${theme.layout.medium};
  text-align: center;
  display: flex;
  gap: ${theme.layout.small};

  .button {
    margin: auto;
    border-radius: 0px;
    height: 36px;
  }

  > :first-child {
    display: flex;
    gap: ${theme.layout.small};

    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;

    > img {
      margin-top: -3px;
    }
  }
`;

const OpenNow = styled.div`
  padding: ${theme.layout.small} ${theme.layout.medium};
  display: flex;
  gap: ${theme.layout.small};
  > :last-child {
    margin-left: auto;
  }
`;

const SelectPub = styled.div`
  position: absolute;
  bottom: 0;
  padding: ${theme.layout.small} ${phone.top};
  background-color: white;

  margin: 0 ${phone.right} ${phone.bottom} ${phone.left}; /* matches phone border */
  width: calc(100% - ${phone.right} - ${phone.left});

  > .button {
    font-family: "Avalon-Book";
    border-radius: 0px;
    width: 100%;
  }
`;

export { Wrapper, Content, Actions, OpenNow, SelectPub };
