import styled from "styled-components";
import Container from "../../../components/container/ContainerStyles";
import { theme } from "../../../styles/theme";

export const StyledViewMap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: auto;
  gap: ${theme.layout.medium};
  height: 100%;
`;

export const StyledInfoContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.normal};

  > span {
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.layout.xSmall} ${theme.layout.medium};

    > * {
      min-width: max-content;
    }
  }
`;
export const StyledMapContainer = styled(Container)`
  padding: 0;
  height: 100%;
  * {
    border-radius: 5px;
  }
`;
