import { useState } from "react";
import { CombinedMenuRule } from "../MenuCombiningTypes";
import { createContainer } from "unstated-next";

const emptyRule = {
  childMenu: { id: undefined, name: "" },
  parentMenu: { id: undefined, name: "" },
  mergeMethod: "",
  id: 0,
  showAtTopLevel: false,
  createdAt: {
    date: "",
    timezone_type: 0,
    timezone: "",
  },
  updatedAt: {
    date: "",
    timezone_type: 0,
    timezone: "",
  },
};

function useRule(initialState: CombinedMenuRule = emptyRule) {
  // Initially the parent rule but is used to be able to cancel after multiple changes
  const [initialRule, setInitialRule] =
    useState<CombinedMenuRule>(initialState);
  // Latest state of the rule
  const [updatedRule, setUpdatedRule] =
    useState<CombinedMenuRule>(initialState);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  return {
    isCollapsed,
    setIsCollapsed,
    updatedRule,
    setUpdatedRule,
    canEdit,
    setCanEdit,
    initialRule,
    setInitialRule,
  };
}

export const RuleContainer = createContainer(useRule);
