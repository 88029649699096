import styled from "styled-components";
import Container from "../../container/ContainerStyles";
import { theme } from "../../../styles/theme";

export const StyledVenueInfo = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  flex-direction: row;
  gap: ${theme.layout.medium} ${theme.layout.medium};

  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.normal};

  > .general {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.small} ${theme.layout.small};

    > span {
      display: flex;
      flex-wrap: wrap;

      > * {
        min-width: max-content;
      }

      &:first-of-type {
        gap: ${theme.layout.xSmall} ${theme.layout.medium};
      }

      &:last-of-type {
        gap: ${theme.layout.xSmall} 0;
      }
    }
  }

  > .address {
    flex: 1 1 auto;
    gap: ${theme.layout.xSmall};

    > h3 {
      font-weight: ${theme.fontWeight.bold};
    }

    > address {
      display: flex;
      flex-direction: column;
      font-size: ${theme.fontSize.small};
      font-weight: ${theme.fontWeight.light};
    }
  }
`;
