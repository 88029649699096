import React, { useMemo } from "react";
import { HistoryContainer } from "../../../../../state-containers/History";

// Components
import Container from "../../../../../components/container/ContainerStyles";
import Button from "../../../../../components/button/ButtonStyles";
import { toast } from "../../../../../components/toast/Toast";

// State
import { AddSpecialHoursStateContainer } from "../../../../../state-containers/AddSpecialHour";

// Settings
import { SettingsKey } from "../../../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

// Functions
import { produce } from "immer";

// API
import { useSpecialHoursEndpoints } from "../../../../../api/specialHours";

function TopContainer() {
  const settings = SettingsContainer.useContainer();
  const { push } = HistoryContainer.useContainer();
  const { addedOpeningTimes } = AddSpecialHoursStateContainer.useContainer();
  const { updateSpecialHour, createSpecialHour } = useSpecialHoursEndpoints();

  const handleUpdate = () => {
    updateSpecialHour(addedOpeningTimes).then(() =>
      toast.success(
        settings.string(
          SettingsKey.AddSpecialHoursUpdateHoursToastSuccess,
          undefined,
          { UPDATEDSPECIALHOUR: addedOpeningTimes.label },
        ),
        {
          position: "bottom-center",
        },
      ),
    );
    push("/manage-venues/special-hours");
  };

  const handleCreate = () => {
    createSpecialHour(
      produce(addedOpeningTimes, (newOpeningTimes) => {
        delete newOpeningTimes.id;
        return newOpeningTimes;
      }),
    ).then(() =>
      toast.success(
        settings.string(
          SettingsKey.AddSpecialHoursCreateHourToastSuccess,
          undefined,
          { CREATEDSPECIALHOUR: addedOpeningTimes.label },
        ),
        {
          position: "bottom-center",
        },
      ),
    );
    push("/manage-venues/special-hours");
  };

  const handleSaveChanges = () => {
    if (addedOpeningTimes.id) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  const datesAreValid = useMemo(() => {
    return addedOpeningTimes.dates.every((date) => {
      return date.label !== "";
    });
  }, [addedOpeningTimes.dates]);

  return (
    <Container>
      <h3>{settings.string(SettingsKey.AddSpecialHoursTitle)}</h3>
      <div>
        <Button
          onClick={() => push("/manage-venues/special-hours")}
          variant="cancel"
        >
          {settings.string(SettingsKey.AddSpecialHoursCancelButton)}
        </Button>
        <Button
          onClick={handleSaveChanges}
          variant="cta"
          disabled={
            !datesAreValid ||
            addedOpeningTimes.label === "" ||
            addedOpeningTimes.dates.length === 0
          }
        >
          {settings.string(SettingsKey.AddSpecialHoursSaveChanges)}
        </Button>
      </div>
    </Container>
  );
}

export default TopContainer;
