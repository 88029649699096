import styled from "styled-components";
import { theme } from "../../../styles/theme";
import Container from "../../container/ContainerStyles";

export const OverlayMessageCardStyles = styled(Container)`
  height: 100%;

  i {
    margin: ${theme.layout.small} auto ${theme.layout.medium};
    font-weight: ${theme.fontWeight.light};
  }

  a,
  a > button {
    margin: auto auto 0;
    width: 100%;
    max-width: 175px;
  }
`;
