import {
  FontSize,
  FontWeight,
  Colours,
  Layout,
  BorderRadius,
} from "./themeTypes";
export * from "./themeTypes";

export const theme = {
  fontWeight: {
    thin: FontWeight.Thin,
    extraLight: FontWeight.ExtraLight,
    light: FontWeight.Light,
    normal: FontWeight.Normal,
    medium: FontWeight.Medium,
    semiBold: FontWeight.SemiBold,
    bold: FontWeight.Bold,
    extraBold: FontWeight.ExtraBold,
    black: FontWeight.Black,
    extraBlack: FontWeight.ExtraBlack,
  },

  fontSize: {
    xSmall: FontSize.XSmall,
    small: FontSize.Small,
    medium: FontSize.Medium,
    large: FontSize.Large,
    xLarge: FontSize.XLarge,
    title: FontSize.Title,
  },

  colour: {
    black: Colours.Black,
    white: Colours.White,
    offWhite: Colours.OffWhite,
    green: Colours.Green,
    red: Colours.Red,
    darkBlue: Colours.DarkBlue,
    blue: Colours.Blue,
    grey: Colours.Grey,
    lightGrey: Colours.LightGrey,
    extraLightGrey: Colours.ExtraLightGrey,
  },

  layout: {
    xSmall: Layout.XSmall,
    small: Layout.Small,
    medium: Layout.Medium,
    large: Layout.Large,
    xLarge: Layout.XLarge,
  },

  borderRadius: {
    small: BorderRadius.Small,
    medium: BorderRadius.Medium,
  },

  transition: {
    normal: "200ms",
  },

  // Colours
  primaryColour: Colours.DarkBlue,
  primaryColourDark: Colours.DarkerBlue,

  // Font
  fontFamily: "Helvetica Neue",
};
