import React from "react";
import Container from "../../../../components/container/ContainerStyles";
import { SpecialHourResponse } from "../../../../state-containers/Venues";
import Button from "../../../../components/button/ButtonStyles";
import Link from "react-router-dom/Link";
import moment from "moment-timezone";
import { icons } from "../../../../assets/icons";
import { Image } from "semantic-ui-react";
import { useSpecialHoursEndpoints } from "../../../../api/specialHours";
import { toast } from "../../../../components/toast/Toast";
import { AddSpecialHoursStateContainer } from "../../../../state-containers/AddSpecialHour";
import { SettingsKey } from "../../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

type Props = {
  specialHours: Array<SpecialHourResponse>;
};

function SpecialHoursList({ specialHours }: Props) {
  const { deleteSpecialHour } = useSpecialHoursEndpoints();
  const settings = SettingsContainer.useContainer();
  const { setAddedOpeningTimes, defaultSpecialHours } =
    AddSpecialHoursStateContainer.useContainer();

  const handleDelete = (id: number, specialHourName: string) => {
    deleteSpecialHour(id).then((res) => {
      if (res.success) {
        toast.success(
          settings.string(SettingsKey.SpecialHoursDeleteSuccess, undefined, {
            SPECIALHOUR_NAME: specialHourName,
          }),
          {
            position: "bottom-center",
          },
        );
      } else {
        toast.error(
          settings.string(SettingsKey.SpecialHoursDeleteFail, undefined, {
            SPECIALHOUR_NAME: specialHourName,
          }),
          {
            position: "bottom-center",
          },
        );
      }
    });
  };

  return (
    <Container>
      <h4>{settings.string(SettingsKey.SpecialHoursListTitle)}</h4>
      <div className="specialHours">
        {specialHours.map((specialHour) => {
          return (
            <div
              role="listitem"
              aria-label="specialHour"
              className="specialHour"
              key={specialHour.id}
            >
              <div>
                <p>{specialHour.label}</p>
                <p className="dates">
                  {specialHour.dates
                    .map((date) => {
                      return moment(date.date).format("DD/MM/YYYY");
                    })
                    .join(", ")}
                </p>
              </div>
              <div>
                <Link to="/manage-venues/special-hours/add">
                  <Image
                    aria-label="edit"
                    src={icons.edit}
                    onClick={() => setAddedOpeningTimes(specialHour)}
                  />
                </Link>
                <Image
                  aria-label="delete"
                  onClick={() =>
                    handleDelete(specialHour.id!, specialHour.label)
                  }
                  src={icons.bin}
                />
              </div>
            </div>
          );
        })}

        <Link to="/manage-venues/special-hours/add">
          <Button
            onClick={() => setAddedOpeningTimes(defaultSpecialHours)}
            large
          >
            {settings.string(SettingsKey.SpecialHoursCTA)}
          </Button>
        </Link>
      </div>
    </Container>
  );
}

export default SpecialHoursList;
