import styled from "styled-components/macro";
import { Checkbox as SemanticCheckbox } from "semantic-ui-react";
import { icons } from "../../assets/icons";

const Checkbox = styled(SemanticCheckbox)`
  &.ui.checkbox {
    &.checked {
      label {
        &:after {
          opacity: 1;
        }
      }
    }

    label {
      &:before {
        border-radius: 50px;
        width: 20px;
        height: 20px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        opacity: 0;
        text-align: center;
        background: url(${icons.selection_on}) no-repeat center;
        background-size: contain;
        background-clip: padding-box;
      }
    }
  }
`;

export default Checkbox;
