import { theme } from "../../styles/theme";
import styled from "styled-components/macro";

export const StyledVenueSearch = styled.div<{ isCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  transition: ${theme.transition.normal};
  overflow: hidden;
  box-shadow: 0 0 20px rgb(34 36 38 / 15%);
  background-color: ${theme.colour.white};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.normal};

  > .filters {
    padding: ${theme.layout.small} ${theme.layout.medium} ${theme.layout.medium};
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.small};

    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    border-top: 1px solid rgba(34, 36, 38, 0.15);

    > .activeFilters {
      display: flex;
      gap: ${theme.layout.xSmall};
      max-width: 100%;
      flex-wrap: wrap;

      > .button {
        font-size: ${theme.fontSize.xSmall};
        color: ${theme.colour.grey};
        background-color: ${theme.colour.lightGrey};

        .icon {
          padding-inline-start: ${theme.layout.medium};
        }
      }
    }

    > .button {
      color: ${theme.colour.white};
      background-color: ${theme.primaryColour};

      &:hover {
        color: ${theme.colour.white};
        background-color: ${theme.primaryColourDark};
      }
    }
  }

  > .list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  text-overflow: ellipsis;
  white-space: ${(p) => (p.isCollapsed ? "nowrap" : "unset")};
  overflow-x: ${(p) => (p.isCollapsed ? "hidden" : "unset")};
  animation: ${(p) =>
    p.isCollapsed ? "unset" : `open ${theme.transition.normal} forwards`};

  @keyframes open {
    0% {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    99% {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    100% {
      white-space: unset;
      overflow-x: unset;
      text-overflow: unset;
    }
  }

  width: ${(p) => (p.isCollapsed ? "0" : "22.5vw")};
  min-width: ${(p) => (p.isCollapsed ? "0" : "22.5vw")};
  @media (max-width: 1024px) {
    width: ${(p) => (p.isCollapsed ? "0" : "40vw")};
    min-width: ${(p) => (p.isCollapsed ? "0" : "40vw")};
  }
  @media (max-width: 700px) {
    width: ${(p) => (p.isCollapsed ? "0" : "100%")};
    min-width: ${(p) => (p.isCollapsed ? "0" : "100%")};
  }
`;

export const StyledListItem = styled.button<{ active: boolean }>`
  text-align: initial;
  padding: ${theme.layout.medium};
  width: 100%;
  color: ${(p) => (p.active ? theme.colour.white : "unset")};
  background-color: ${(p) =>
    p.active ? theme.colour.grey : theme.colour.white};

  border: 0;
  border-bottom: 1px solid rgb(179, 179, 179);

  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.light};
  h4 {
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontWeight.medium};
    color: ${(p) => (p.active ? theme.colour.white : theme.colour.grey)};
  }

  > .venue-id {
    padding: ${theme.layout.xSmall} 0;
    color: ${(p) => (p.active ? theme.colour.white : theme.colour.grey)};

    font-weight: ${theme.fontWeight.normal};
  }

  &:hover {
    cursor: pointer;
    background-color: ${(p) =>
      p.active ? theme.colour.grey : theme.colour.lightGrey};
  }
`;
