import { VenueDetails } from "./venueDetails";
import { Venue } from "./venues";

type VenueOrDetails = Venue | VenueDetails;

// NOTE: check venues only based on its properties for future compatibility
// i.e.: use hasMultipleAddressLines & not isVenueDO

export const hasMultipleAddressLines = (
  v: VenueOrDetails,
): v is VenueOrDetails & { line2: string } => "line2" in v;

export const getOpeningtimes = (venue: VenueDetails) => {
  if ("rows" in venue.openingTimes) {
    return venue.openingTimes.rows;
  }
  return venue.openingTimes;
};

export const getImages = (venue: VenueDetails) => {
  if ("images" in venue) {
    return venue.images;
  }
  return undefined;
};
