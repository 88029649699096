import React from "react";
import moment from "moment";
import { SpecialTime } from "./TimeTableTypes";
import { OpeningHours, SpecialHours, TableEntry } from "./TimeTableStyles";
import { VenueDetails, OpeningTimes } from "../../api/types/venueDetails";
import { getOpeningtimes } from "../../api/types/guards";

const DEFAULT_OPENING_TIMES: OpeningTimes = [
  { label: "Monday", times: "08:00am - 12:00am", date: "" },
  { label: "Tuesday", times: "08:00am - 12:00am", date: "" },
  { label: "Wednesday", times: "08:00am - 12:00am", date: "" },
  { label: "Thursday", times: "08:00am - 12:00am", date: "" },
  { label: "Friday", times: "08:00am - 01:00am", date: "" },
  { label: "Saturday", times: "08:00am - 01:00am", date: "" },
  { label: "Sunday", times: "08:00am - 12:00am", date: "" },
];

const formatHour = (hr: moment.MomentInput) =>
  moment(hr, "HH:mm").format("HH:mma");

interface Props {
  venue: VenueDetails;
  special?: {
    label: string;
    dates: SpecialTime[];
  };
}

const TimeTable = ({ venue, special }: Props) => {
  venue.openingTimes = DEFAULT_OPENING_TIMES;

  return (
    <>
      <OpeningHours>
        {getOpeningtimes(venue).map(({ label, times }, i) => (
          <TableEntry key={i} today={label === moment().format("dddd")}>
            <span>{label}</span>
            <span className="placeholder" />
            <span>{times}</span>
          </TableEntry>
        ))}
      </OpeningHours>

      {special && (
        <SpecialHours>
          <h4>{special.label}</h4>
          <OpeningHours>
            {special.dates.map(
              ({ label, closed, openingHour, closingHour }, i) => (
                <TableEntry key={i}>
                  <span>{label}</span>
                  <span className="placeholder" />
                  <span>
                    {closed
                      ? "CLOSED"
                      : formatHour(openingHour) +
                        " - " +
                        formatHour(closingHour)}
                  </span>
                </TableEntry>
              ),
            )}
          </OpeningHours>
        </SpecialHours>
      )}
    </>
  );
};

export default TimeTable;
