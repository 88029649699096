import React, { useEffect, useMemo } from "react";
import { theme } from "../../../styles/theme";
import Button from "../../../components/button/ButtonStyles";
import Grid from "../../../components/grid/GridStyles";
import { RuleContainer } from "../rule/RuleState";
import { Select } from "semantic-ui-react";
import Input from "../../../components/input/InputStyles";
import { useCombinedMenusEndpoints } from "../../../api/combinedMenus";
import { StyledRuleExpanded } from "./RuleExpandedStyles";
import { MenuCombiningContainer } from "../../../state-containers/MenuCombining";
import { toast } from "../../../components/toast/Toast";
import { hasEmptyValues } from "./helpers";
import { SettingsKey } from "../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";
import isEqual from "lodash/isEqual";

type Props = {
  isNew?: boolean;
  cancelNewRule?: () => void;
};

function RuleExpanded({ isNew, cancelNewRule }: Props) {
  const {
    updatedRule,
    setUpdatedRule,
    initialRule,
    setIsCollapsed,
    canEdit,
    setCanEdit,
    setInitialRule,
  } = RuleContainer.useContainer();
  const { parentMenu, childMenu, mergeMethod, id } = updatedRule;
  const { deleteCombinedMenu, updateCombinedMenu, createCombinedMenu } =
    useCombinedMenusEndpoints();
  const { fetchData } = MenuCombiningContainer.useContainer();
  const settings = SettingsContainer.useContainer();

  const hasBeenEdited = useMemo(() => {
    if (!isNew) {
      return !isEqual(initialRule, updatedRule);
    } else {
      return;
    }
  }, [initialRule, updatedRule, isNew]);

  useEffect(() => {
    if (isNew) {
      setCanEdit(true);
    }
  }, [isNew, setCanEdit]);

  function handleCreateRule() {
    createCombinedMenu({ ...updatedRule })
      .then(() => {
        toast.success(
          settings.string(SettingsKey.MenuCombiningCreateSuccess),
          {
            position: "bottom-center",
          },
        );
        if (cancelNewRule) {
          cancelNewRule();
        }
        fetchData();
      })
      .catch(() => {
        toast.error(settings.string(SettingsKey.MenuCombiningCreateFail), {
          position: "bottom-center",
        });
      });
  }

  function handleConfirm() {
    setIsCollapsed(true);
    setInitialRule(updatedRule);
    updateCombinedMenu(id.toString(), updatedRule)
      .then(() => {
        toast.success(
          settings.string(SettingsKey.MenuCombiningUpdateSuccess),
          {
            position: "bottom-center",
          },
        );
        fetchData();
      })
      .catch(() => {
        toast.error(settings.string(SettingsKey.MenuCombiningUpdateFail), {
          position: "bottom-center",
        });
      });
  }

  function handleDeleteRule() {
    deleteCombinedMenu(id.toString())
      .then(() => {
        toast.error(settings.string(SettingsKey.MenuCombiningDeleteSuccess), {
          position: "bottom-center",
          closeButton: (
            <Button
              variant="secondary"
              onClick={() => {
                handleCreateRule();
                toast.dismiss();
              }}
            >
              {settings.string(SettingsKey.MenuCombiningUndoDelete)}
            </Button>
          ),
        });
        fetchData();
      })
      .catch(() => {
        toast.error(settings.string(SettingsKey.MenuCombiningDeleteFail), {
          position: "bottom-center",
        });
      });
  }

  return (
    <StyledRuleExpanded>
      <Grid gap={theme.layout.medium}>
        <div>
          <div>
            <h3 id="parentMenuId">
              {settings.string(SettingsKey.MenuCombiningParentID)}
            </h3>
            <Input
              aria-labelledby="parentMenuId"
              disabled={!canEdit}
              value={parentMenu.id?.toString() || ""}
              onChange={(e) =>
                !isNaN(+e.target.value) &&
                setUpdatedRule({
                  ...updatedRule,
                  parentMenu: {
                    ...parentMenu,
                    id:
                      e.target.value !== ""
                        ? Number(e.target.value)
                        : undefined,
                  },
                })
              }
            />
          </div>
          <div>
            <h3 id="childMenuId">
              {settings.string(SettingsKey.MenuCombiningChildID)}
            </h3>
            <Input
              aria-labelledby="childMenuId"
              disabled={!canEdit}
              value={childMenu.id?.toString() || ""}
              onChange={(e) =>
                !isNaN(+e.target.value) &&
                setUpdatedRule({
                  ...updatedRule,
                  childMenu: {
                    ...childMenu,
                    id:
                      e.target.value !== ""
                        ? Number(e.target.value)
                        : undefined,
                  },
                })
              }
            />
          </div>
          <div>
            <h3 id="mergeMethod">
              {settings.string(SettingsKey.MenuCombiningMergeMethod)}
            </h3>
            <Select
              aria-labelledby="mergeMethod"
              disabled={!canEdit}
              onChange={(e, data) =>
                setUpdatedRule({
                  ...updatedRule,
                  mergeMethod: data.value ? data.value.toString() : "",
                })
              }
              value={mergeMethod}
              options={[
                {
                  text: settings.string(
                    SettingsKey.MenuCombiningMergeMethodSingleEntry,
                  ),
                  value: "singleEntry",
                },
                {
                  text: settings.string(
                    SettingsKey.MenuCombiningMergeMethodSubMenus,
                  ),
                  value: "subMenus",
                },
              ]}
            />
          </div>
        </div>
        <div>
          <div>
            <h3 id="parentMenuName">
              {settings.string(SettingsKey.MenuCombiningParentName)}
            </h3>
            <Input
              aria-labelledby="parentMenuName"
              disabled={!canEdit}
              value={parentMenu.name}
              onChange={(e) =>
                setUpdatedRule({
                  ...updatedRule,
                  parentMenu: { ...parentMenu, name: e.target.value },
                })
              }
            />
          </div>
          <div>
            <h3 id="childMenuName">
              {settings.string(SettingsKey.MenuCombiningChildName)}
            </h3>
            <Input
              aria-labelledby="childMenuName"
              disabled={!canEdit}
              value={childMenu.name}
              onChange={(e) =>
                setUpdatedRule({
                  ...updatedRule,
                  childMenu: { ...childMenu, name: e.target.value },
                })
              }
            />
          </div>
          <div className="buttonContainer">
            <Button
              variant="destructive"
              onClick={() => {
                if (isNew && cancelNewRule) {
                  cancelNewRule();
                } else {
                  if (canEdit) {
                    setCanEdit(false);
                    setUpdatedRule(initialRule);
                  } else {
                    handleDeleteRule();
                  }
                }
              }}
            >
              {isNew
                ? settings.string(SettingsKey.MenuCombiningCancelEditing)
                : canEdit
                ? settings.string(SettingsKey.MenuCombiningCancelEditing)
                : settings.string(SettingsKey.MenuCombiningDeleteRule)}
            </Button>
            <Button
              disabled={
                (canEdit && !isNew && !hasBeenEdited) ||
                !hasEmptyValues(updatedRule)
              }
              onClick={() => {
                if (isNew) {
                  handleCreateRule();
                } else {
                  if (canEdit) {
                    handleConfirm();
                  } else {
                    setCanEdit(true);
                  }
                }
              }}
            >
              {isNew
                ? settings.string(SettingsKey.MenuCombiningConfirmChanges)
                : canEdit
                ? settings.string(SettingsKey.MenuCombiningConfirmChanges)
                : settings.string(SettingsKey.MenuCombiningEditRule)}
            </Button>
          </div>
        </div>
      </Grid>
    </StyledRuleExpanded>
  );
}

export default RuleExpanded;
