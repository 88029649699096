import { useState } from "react";

export default function useSideMenu() {
  const [openDropdown, setOpenDropdown] = useState<string>("");
  const [activeMenuItem, setActiveMenuItem] = useState<string>("Venues");

  return {
    openDropdown,
    setOpenDropdown,
    activeMenuItem,
    setActiveMenuItem,
  };
}
