import React from "react";
import { useInitializeSubstitutions } from "../../helpers/initializeSubstitutions";
import { Switch, Route } from "react-router-dom";
import { GlobalStyle } from "../../styles";
import Home from "../../pages/home/Home";
import ManageVenues from "../../pages/manageVenues/ManageVenues";
import MenuCombining from "../../pages/menuCombining/MenuCombining";
import Toast from "../toast/Toast";
import SpecialHours from "../../pages/manageVenues/specialHours/SpecialHours";
import AddSpecialHours from "../../pages/manageVenues/specialHours/addSpecialHours/AddSpecialHours";
import ViewMap from "../../pages/manageVenues/viewMap/ViewMap";

function App() {
  useInitializeSubstitutions();

  return (
    <React.Fragment>
      <GlobalStyle />
      <Switch>
        <Route path="/manage-venues/view-map" component={ViewMap} />
        <Route
          path={"/manage-venues/special-hours/add"}
          component={AddSpecialHours}
        />
        <Route path={"/manage-venues/special-hours"} component={SpecialHours} />
        <Route path={"/manage-venues"} component={ManageVenues} />
        <Route path={"/manage-menu-combining"} component={MenuCombining} />
        <Route path={"/"} component={Home} />
      </Switch>
      <Toast />
    </React.Fragment>
  );
}

export default App;
