import { RequesterContainer } from "@JS/nn4m-api-request";
import { useCallback } from "react";
import { Collection } from "../settings/types";
import { StatusResponse } from "./types/statusResponse";
import { VenueTable } from "./types/venueTables";

export type VenueTablesResponse = {
  tables: VenueTable[];
};

export function useVenueTablesEndpoints() {
  const { requestFromCollection } = RequesterContainer.useContainer();

  const getVenueTables = useCallback(
    (id: number): Promise<VenueTablesResponse> =>
      requestFromCollection(Collection.GetVenueTables, {
        VENUE_ID: String(id),
      }),
    [requestFromCollection],
  );

  const updateVenueTables = useCallback(
    (
      id: number,
      tablesToEnableIds: number[],
      tablesToDisableIds: number[],
    ): Promise<StatusResponse> =>
      requestFromCollection(Collection.UpdateVenueTables, {
        VENUE_ID: String(id),
        TABLES_TO_ENABLE_IDS: JSON.stringify(tablesToEnableIds),
        TABLES_TO_DISABLE_IDS: JSON.stringify(tablesToDisableIds),
      }),
    [requestFromCollection],
  );

  return {
    getVenueTables,
    updateVenueTables,
  };
}
