import React from "react";
import { StyledLabel } from "./LabelStyles";

type Props = {
  children: React.ReactNode;
};

function Label({ children }: Props) {
  return <StyledLabel>{children}</StyledLabel>;
}

export default Label;
