import styled from "styled-components";
import { theme } from "../../styles/theme";

const OpeningHours = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: "Avalon-Book";
`;

const TableEntry = styled.div<{ today?: boolean }>`
  display: flex;
  width: 100%;

  font-weight: ${(p) =>
    p.today ? theme.fontWeight.medium : theme.fontWeight.light};

  span {
    flex: 0 0 max-content;
    width: max-content;
  }

  .placeholder {
    flex: 1;
    width: 100%;
  }
`;

const SpecialHours = styled.div`
  font-weight: 300;
  font-family: "Avalon-Book";
  margin-top: ${theme.layout.medium};

  h4 {
    margin-bottom: ${theme.layout.small};
  }
`;

export { OpeningHours, TableEntry, SpecialHours };
