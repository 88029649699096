import { KeycloakContainer, KeycloakTokenParsed } from "@JS/nn4m-keycloak-auth";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

interface KeycloakToken extends KeycloakTokenParsed {
  rcodes: string[];
}

const storedRCodeKey = "nn4m-venue-manager-rcode";

function useRCode() {
  const { getParsedToken } = KeycloakContainer.useContainer();

  const parsedToken = getParsedToken() as KeycloakToken;
  const availableRCodes = parsedToken.rcodes;

  const storedRCode = localStorage.getItem(storedRCodeKey);

  const [selectedRCode, setSelectedRCode] = useState<string>(
    storedRCode ?? availableRCodes[0],
  );

  useEffect(() => {
    localStorage.setItem(storedRCodeKey, selectedRCode);
  }, [selectedRCode, storedRCode]);

  return { availableRCodes, selectedRCode, setSelectedRCode };
}

export const RCodeContainer = createContainer(useRCode);
