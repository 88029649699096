import React from "react";
import AutoCompleteInput from "./AutoCompletingInput/AutoCompletingInput";
import { StyledVenueSearch, StyledListItem } from "./VenueSearchStyles";
import { Button, Icon } from "semantic-ui-react";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../../settings/types";
import { useClientSettings } from "../../helpers/clientSettings";
import { useSuggestions } from "./useSuggestions";
import { VenueContainer } from "../../state-containers/Venues";
import { DataLoader } from "@JS/nn4m-api-request";
import { hasMultipleAddressLines } from "../../api/types/guards";

interface Props {
  isCollapsed?: boolean;
  toggle(): void;
}

const VenueSearch = ({ isCollapsed, toggle }: Props) => {
  const { venues, selectedVenue, selectVenue } = VenueContainer.useContainer();
  const settings = SettingsContainer.useContainer();
  const { getClientSetting } = useClientSettings();

  const defaultSuggestions: Record<string, string> = getClientSetting(
    SettingsKey.VenueFiltersAutocomplete,
  );

  const {
    query,
    queriedVenues,
    suggestions,
    selectedFilters,

    handleInput,
    handleSelect,
    handleFilterClick,
    removeFilter,
  } = useSuggestions(defaultSuggestions);

  const maxVenues = settings.integer(SettingsKey.MaxVenuesInVenueSearch);
  const uiFilter = settings.string(SettingsKey.Filter);
  const uiShowingResults = settings.string(
    SettingsKey.ShowingResults,
    undefined,
    {
      DISPLAYED_RESULTS: String(Math.min(queriedVenues.length, maxVenues!)),
      MAX_RESULTS: String(queriedVenues.length),
    },
  );

  return (
    <StyledVenueSearch isCollapsed={isCollapsed}>
      <DataLoader data={[venues]}>
        <AutoCompleteInput
          value={query}
          onInput={handleInput}
          onSelect={handleSelect}
          suggestions={suggestions}
        />

        <div className="filters">
          {uiShowingResults}

          <span className="activeFilters">
            {selectedFilters.map((filter, i) => (
              <Button key={i} onClick={() => removeFilter(filter)}>
                {filter.text}
                <Icon name={"remove"} />
              </Button>
            ))}
          </span>

          <Button
            fluid
            disabled={!!suggestions.length}
            onClick={handleFilterClick}
          >
            {uiFilter}
          </Button>
        </div>

        <div className="list">
          {queriedVenues.slice(0, maxVenues).map((v) => (
            <StyledListItem
              role="listitem"
              key={v.venueId}
              active={selectedVenue.data?.venueId === v.venueId}
              disabled={selectedVenue.data?.venueId === v.venueId}
              onClick={() => {
                toggle();
                selectVenue(v.venueId);
              }}
            >
              <h4>{v.name}</h4>
              <p className="venue-id">
                {settings.string(SettingsKey.VenueId, undefined, {
                  ID: String(v.venueId),
                })}
              </p>
              <address>
                {hasMultipleAddressLines(v)
                  ? [v.line1, v.line2].join(", ")
                  : v.line1}
                <br />
                {[v.town, v.postcode].join(", ")}
              </address>
            </StyledListItem>
          ))}
        </div>
      </DataLoader>
    </StyledVenueSearch>
  );
};

export default VenueSearch;
