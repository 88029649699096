import { useState } from "react";

export default function useMenuCombining() {
  const [newRule, setNewRule] = useState<boolean>(false);
  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  return {
    newRule,
    setNewRule,
    isModalActive,
    setIsModalActive,
  };
}
