import React from "react";

// Components
import TimeTable from "../../../../../components/timeTable/TimeTable";
import Container from "../../../../../components/container/ContainerStyles";
import Label from "../../../../../components/label/Label";

// Functions
import moment from "moment-timezone";
import { isOpened } from "../helpers";

// Settings
import { SettingsKey } from "../../../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

// State
import { VenueContainer } from "../../../../../state-containers/Venues";

// Types
import { getOpeningtimes } from "../../../../../api/types/guards";

function OpeningsTimeContainer() {
  const settings = SettingsContainer.useContainer();
  const { selectedVenue, selectedVenueTimezone } =
    VenueContainer.useContainer();
  const yourTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const venueIsOpenState =
    selectedVenueTimezone &&
    selectedVenue.data &&
    isOpened(
      getOpeningtimes(selectedVenue.data),
      selectedVenueTimezone.timeZoneId,
    )
      ? settings.string(SettingsKey.AddSpecialHoursOpeningHoursOpen)
      : settings.string(SettingsKey.AddSpecialHoursOpeningHoursClosed);

  return (
    <Container>
      <div>
        <>
          <h4>
            {settings.string(
              SettingsKey.AddSpecialHoursOpeningHours,
              undefined,
              {
                IS_OPEN_STATE: venueIsOpenState,
              },
            )}
          </h4>
          {selectedVenueTimezone &&
            selectedVenueTimezone.timeZoneId !== yourTimeZone && (
              <Label>
                {`${moment()
                  .tz(selectedVenueTimezone.timeZoneId)
                  .format(
                    "dddd hh:mm a",
                  )} ${selectedVenueTimezone.timeZoneId.replace(/_/g, " ")}`}
              </Label>
            )}
        </>
      </div>
      {selectedVenue.data && <TimeTable venue={selectedVenue.data} />}
    </Container>
  );
}

export default OpeningsTimeContainer;
