import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const StyledMenuCombining = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;

  .pageHeading {
    width: 100%;
    height: fit-content;
    border-radius: ${theme.borderRadius.small};
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: ${theme.layout.small};
    background-color: ${theme.colour.white};

    h3 {
      font-weight: ${theme.fontWeight.light};
      color: ${theme.colour.grey};
    }

    .pageTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        h3 {
          margin-top: 5px;
        }
      }
    }

    .subHeading {
      padding: ${theme.layout.medium};
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const StyledModalContent = styled.h3`
  padding: ${theme.layout.medium};
`;
