import styled from "styled-components/macro";
import { DayPickerInputWrapper } from "./DatePicker";
import "react-day-picker/lib/style.css";
import { theme } from "../../styles/theme";
import { icons } from "../../assets/icons";

const DatePicker = styled(DayPickerInputWrapper)`
  &.DayPickerInput {
    position: relative;
    cursor: pointer;

    > input {
      border: 1px ${theme.colour.lightGrey} solid;
      border-radius: ${theme.borderRadius.medium};
      padding: 0 ${theme.layout.medium};
      background-color: ${theme.colour.extraLightGrey};
      height: 40px;
      width: 100% !important;
      outline: none;
      font-size: ${theme.fontSize.small};
      font-weight: ${theme.fontWeight.normal};
      color: ${theme.colour.black};
      cursor: pointer;
    }

    &:after {
      position: absolute;
      right: 10px;
      top: 50%;
      z-index: 100;
      width: 10px;
      transform: translate(-50%, -50%);
      height: 10px;
      content: "";
      background: url(${icons.accordion_down}) no-repeat center;
      pointer-events: none;
    }
  }
`;

export default DatePicker;
