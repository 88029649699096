import React from "react";
import Map from "../map/Map";
import { Button, Image, Divider, Icon } from "semantic-ui-react";
import { images } from "../../assets/images";
import { icons } from "../../assets/icons";
import TimeTable from "../timeTable/TimeTable";
import { SpecialTime } from "../timeTable/TimeTableTypes";
import "./fonts/fonts.css";
import {
  Wrapper,
  Content,
  Actions,
  OpenNow,
  SelectPub,
} from "./PhonePreviewStyles";
import { useClientSettings } from "../../helpers/clientSettings";
import { SettingsKey } from "../../settings/types";
import { VenueDetails } from "../../api/types/venueDetails";
import { hasMultipleAddressLines, getImages } from "../../api/types/guards";

interface Props {
  venue: VenueDetails;
  specialHours?: {
    label: string;
    dates: SpecialTime[];
  };
}

interface PhonePreviewUi {
  header: string;
  imageLabel: string;
  distance: string;
  action: string;
  status: [string, string];
  bottomButton: string;
}

const PhonePreview = ({ venue, specialHours }: Props) => {
  const { getClientSetting } = useClientSettings();
  const venueImages = getImages(venue);
  const ui = getClientSetting<PhonePreviewUi>(
    SettingsKey.PhonePreview,
    undefined,
    { NUMBER_OF_IMAGES: `${venueImages?.length ?? 0}` },
  );

  return (
    <Wrapper>
      <Image id="phone-border" src={images.phoneBorder} />
      <Image id="status-bar" src={images.statusBar} />

      <Content>
        <div id="top-bar">
          <Image src={icons.back} />
          {ui?.header}
          <Image src={icons.search} />
        </div>

        {venueImages && <Image id="venue-image" src={venueImages![0]} />}

        <div id="venue-image-text">{ui?.imageLabel}</div>

        <div id="venue-name">
          {venue.name}
          <span>{ui?.distance}</span>
        </div>

        <div id="venue-address">
          {[
            venue.line1,
            hasMultipleAddressLines(venue) && venue.line2,
            venue.town,
            venue.postcode,
          ]
            .filter(Boolean)
            .join(", ")}
        </div>

        <Actions>
          <Button>
            <Image src={icons.telephone} />
            <span>{venue.telephone}</span>
          </Button>
          <Button color="black">{ui?.action}</Button>
        </Actions>

        <Divider />

        <OpenNow>
          <Image src={icons.clock} />
          {ui?.status[0]}
          <span>
            {ui?.status[1]} <Icon name="caret up" />
          </span>
        </OpenNow>

        <Divider />
        <div className="timetable">
          <TimeTable venue={venue} special={specialHours} />
        </div>

        <Map center={{ lat: venue.lat, lng: venue.long }} />
      </Content>

      <SelectPub>
        <Button color="blue">{ui?.bottomButton}</Button>
      </SelectPub>
    </Wrapper>
  );
};

export default PhonePreview;
