import type { PropsWithChildren } from "react";
import React from "react";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../settings/types";
import {
  defaultKeycloakInitOptions,
  KeycloakContainer,
} from "@JS/nn4m-keycloak-auth";

function AuthProvider({ children }: PropsWithChildren<{}>) {
  const settings = SettingsContainer.useContainer();

  const initOptions = defaultKeycloakInitOptions;
  const config = {
    url: settings.string(SettingsKey.KeycloakHost),
    realm: settings.string(SettingsKey.KeycloakRealm),
    clientId: settings.string(SettingsKey.KeycloakClientId),
  };

  return settings.hasSettings() ? (
    <KeycloakContainer.Provider initialState={{ initOptions, config }}>
      {children}
    </KeycloakContainer.Provider>
  ) : null;
}

export { AuthProvider };
