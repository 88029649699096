import React from "react";
import { StyledHero } from "./HeroStyles";
import { Link } from "react-router-dom";
import Button from "../button/ButtonStyles";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../../settings/types";
import { useClientSettings } from "../../helpers/clientSettings";

type Props = {
  bannerImage: string;
};

type HeroCTA = {
  text: string;
  link: string;
};

function Hero({ bannerImage }: Props) {
  const settings = SettingsContainer.useContainer();
  const { getClientSetting } = useClientSettings();
  const cta = settings.json<HeroCTA>(SettingsKey.HeroCTA);

  return (
    <StyledHero role="figure">
      <div className="imageContainer">
        <img src={bannerImage} alt="heroImage" />
      </div>
      <div className="inner">
        <h1>{getClientSetting<string>(SettingsKey.HeroTitle)}</h1>
        <Link to={cta.link}>
          <Button variant="cta" large>
            {cta.text}
          </Button>
        </Link>
      </div>
    </StyledHero>
  );
}

export default Hero;
