import React from "react";
import TimeSlider from "./TimeSlider";
import { Icon } from "semantic-ui-react";
import moment from "moment-timezone";

type TimeSelectPopUpProps = {
  timeValue: {
    hour: number;
    minute: number;
  };
  updatefunc: (val: { hour: number; minute: number }) => void;
};

function TimeSelectPopUp({ timeValue, updatefunc }: TimeSelectPopUpProps) {
  const sunIsUp = +timeValue.hour >= 6 && +timeValue.hour <= 18;
  return (
    <div className="popUp">
      <div>
        <div className="sliderHeader">
          <p>Hour: {moment(timeValue.hour, "HH").format("ha")}</p>
          <Icon
            name={sunIsUp ? "sun" : "moon"}
            color={sunIsUp ? "yellow" : "black"}
            className="timeIcon"
          />
        </div>
        <div className="sliderContainer">
          <TimeSlider
            updatefunc={(val) =>
              updatefunc({ hour: val, minute: timeValue.minute })
            }
            value={timeValue.hour}
            hours
            minTime={0}
            maxTime={23}
          />
        </div>
      </div>
      <div>
        <div className="sliderHeader">
          <p>Minute: {timeValue.minute}</p>
        </div>
        <div className="sliderContainer">
          <TimeSlider
            updatefunc={(val) =>
              updatefunc({ hour: timeValue.hour, minute: val })
            }
            value={timeValue.minute}
            minTime={0}
            maxTime={59}
          />
        </div>
      </div>
    </div>
  );
}

export default TimeSelectPopUp;
