import React, { useEffect, useMemo } from "react";
import { icons } from "../../../assets/icons";
import { StyledRule } from "./RuleStyles";
import { RuleContainer } from "./RuleState";
import produce from "immer";
import RuleExpanded from "../ruleExpanded/RuleExpanded";
import RuleCollapsed from "../ruleCollapsed/RuleCollapsed";
import { MenuCombiningContainer } from "../../../state-containers/MenuCombining";
import { SettingsKey } from "../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";
import isEqual from "lodash/isEqual";

type Props = {
  isNew?: boolean;
  cancelNewRule?: () => void;
};

function Rule({ isNew, cancelNewRule }: Props) {
  const { isCollapsed, setIsCollapsed, updatedRule, initialRule } =
    RuleContainer.useContainer();
  const { setEditedRules } = MenuCombiningContainer.useContainer();
  const settings = SettingsContainer.useContainer();

  const hasBeenEdited = useMemo(() => {
    if (!isNew) {
      return !isEqual(initialRule, updatedRule);
    }
    return true;
  }, [initialRule, updatedRule, isNew]);

  // If rule is pending disable make live button
  useEffect(() => {
    if (!isNew) {
      setEditedRules((prevState) => {
        const isInEditedRules = prevState.some(
          (rule) => rule.id === updatedRule.id,
        );
        const ruleIndex = prevState.findIndex(
          (rule) => rule.id === updatedRule.id,
        );

        if (hasBeenEdited && !isInEditedRules) {
          return [...prevState, updatedRule];
        } else if (hasBeenEdited && isInEditedRules) {
          return produce(prevState, (draftState) => {
            draftState[ruleIndex] = updatedRule;
          });
        } else if (!hasBeenEdited && isInEditedRules) {
          return produce(prevState, (draftState) => {
            draftState.splice(ruleIndex, 1);
          });
        } else {
          return prevState;
        }
      });
    }
  }, [hasBeenEdited, isNew, setEditedRules, updatedRule]);

  useEffect(() => {
    if (isNew) {
      setIsCollapsed(false);
    }
  }, [isNew, setIsCollapsed]);

  return (
    <StyledRule role="listitem" edited={hasBeenEdited}>
      <div className="header">
        <div className="statusContainer">
          <div className="divider">
            <h3>{settings.string(SettingsKey.MenuCombiningRuleStatus)}</h3>
          </div>
          <h3>
            {hasBeenEdited
              ? settings.string(SettingsKey.MenuCombiningPendingStatus)
              : settings.string(SettingsKey.MenuCombiningLiveStatus)}
          </h3>
        </div>
        <div role="button" onClick={() => setIsCollapsed(!isCollapsed)}>
          <h3>
            {isCollapsed
              ? settings.string(SettingsKey.MenuCombiningShowRule)
              : settings.string(SettingsKey.MenuCombiningHideRule)}
          </h3>
          <img src={icons.accordion_down} alt="accordion" />
        </div>
      </div>
      {isCollapsed ? (
        <RuleCollapsed />
      ) : (
        <RuleExpanded cancelNewRule={cancelNewRule} isNew={isNew} />
      )}
    </StyledRule>
  );
}

export default Rule;
