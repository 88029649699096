import React from "react";
import Grid from "../../../components/grid/GridStyles";
import { StyledRuleCollapsed } from "./RuleCollapsedStyles";
import { RuleContainer } from "../rule/RuleState";
import { SettingsKey } from "../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

function RuleCollapsed() {
  const { initialRule } = RuleContainer.useContainer();
  const { parentMenu, childMenu, mergeMethod } = initialRule;
  const settings = SettingsContainer.useContainer();

  return (
    <StyledRuleCollapsed>
      <Grid columns={3}>
        <div>
          <div className="left">
            <h3>{settings.string(SettingsKey.MenuCombiningParentID)}</h3>
            <h3>{settings.string(SettingsKey.MenuCombiningChildID)}</h3>
          </div>
          <div className="dividers">
            <div className="divider" /> <div className="divider" />
          </div>
          <div className="right">
            <h3>{parentMenu.id}</h3>
            <h3>{childMenu.id}</h3>
          </div>
        </div>
        <div>
          <div className="left">
            <h3>{settings.string(SettingsKey.MenuCombiningParentName)}</h3>
            <h3>{settings.string(SettingsKey.MenuCombiningChildName)}</h3>
          </div>
          <div className="dividers">
            <div className="divider" />
            <div className="divider" />
          </div>
          <div className="right">
            <h3>{parentMenu.name}</h3>
            <h3>{childMenu.name}</h3>
          </div>
        </div>
        <div>
          <div className="left">
            <h3>{settings.string(SettingsKey.MenuCombiningMergeMethod)}</h3>
          </div>
          <div className="dividers">
            <div className="divider" />
          </div>
          <div className="right">
            <h3>{mergeMethod}</h3>
          </div>
        </div>
      </Grid>
    </StyledRuleCollapsed>
  );
}

export default RuleCollapsed;
