import ReactDOM from "react-dom";
import React from "react";
import { CombinedProvider } from "./state-containers/StateContainer";
import App from "./components/app/App";
import { BrowserRouter } from "react-router-dom";
import { BaseRoutes } from "./components/baseRoutes/BaseRoutes";

const rootNode = document.getElementById("root");
if (!rootNode) {
  throw new Error("reactDOM could not render, no root element found");
}

ReactDOM.render(
  <BrowserRouter>
    <CombinedProvider>
      <BaseRoutes>
        <App />
      </BaseRoutes>
    </CombinedProvider>
  </BrowserRouter>,

  rootNode,
);
