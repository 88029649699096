import React from "react";
import { icons } from "../../assets/icons";
import { ModalProps } from "semantic-ui-react";
import {
  StyledActions,
  StyledContent,
  StyledHeader,
  StyledModal,
} from "./ModalStyles";

interface Props extends ModalProps {
  title: React.ReactNode;
  children: React.ReactNode;
  actions: React.ReactNode;
  icon?: string;
}

const Modal = ({ title, children, icon, actions, ...props }: Props) => (
  <StyledModal role="dialog" {...props}>
    <StyledHeader>
      {icon && <img src={icons[icon]} alt="icon" />}
      <h2>{title}</h2>
    </StyledHeader>
    <StyledContent>{children}</StyledContent>
    <StyledActions>{actions}</StyledActions>
  </StyledModal>
);

export default Modal;
