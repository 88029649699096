import type { PropsWithChildren } from "react";
import { Switch } from "react-router-dom";
import {
  RouteEnvironmentSwitcher,
  SettingsContainer,
} from "@JS/nn4m-settings";
import { RolesRoute } from "@JS/nn4m-keycloak-auth";
import { StyledNotAllowed } from "./NotAllowedStyles";
import React from "react";
import { SettingsKey } from "../../settings/types";

export function BaseRoutes({ children }: PropsWithChildren<{}>) {
  const settings  = SettingsContainer.useContainer();

  return (
    <Switch>
      {RouteEnvironmentSwitcher()}
      <RolesRoute
        path={"/"}
        roles={[settings.string(SettingsKey.KeycloakAccessRole)]}
        notAllowed={<StyledNotAllowed />}
      >
        {children}
      </RolesRoute>
    </Switch>
  );
}
