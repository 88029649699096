import React, { PropsWithChildren, useState } from "react";
import { Image, Icon } from "semantic-ui-react";
import { icons } from "../../assets/icons";
import SideMenu from "../../components/sideMenu/SideMenu";
import { VenueContainer } from "../../state-containers/Venues";
import VenueSearch from "../venueSearch/VenueSearch";
import { StyledHeader, StyledNav } from "./NavStyles";

type Props = PropsWithChildren<{
  title: React.ReactNode;
  hasSearchButton?: boolean;
  back(): void;
}>;

function Nav({ hasSearchButton, back, title, children }: Props) {
  const { selectedVenue } = VenueContainer.useContainer();

  const [isSidemenuCollapsed, setIsSidemenuCollapsed] = useState(true);
  const toggleSideMenu = () => setIsSidemenuCollapsed((b) => !b);

  const [isSearchMenuCollapsed, setIsSearchMenuCollapsed] = useState(
    selectedVenue.isReady,
  );
  const toggleSearchMenu = () => setIsSearchMenuCollapsed((b) => !b);

  return (
    <StyledNav>
      <SideMenu isCollapsed={isSidemenuCollapsed} />

      <div className="not-sidebar">
        <StyledHeader>
          <button onClick={toggleSideMenu}>
            <Icon name={isSidemenuCollapsed ? "sidebar" : "remove"} />
          </button>
          {hasSearchButton && (
            <button onClick={toggleSearchMenu}>
              <Image ui src={icons.nav_search} />
            </button>
          )}
          <button onClick={back} aria-label="backButton">
            <Image src={icons.nav_back} />
          </button>
          {title}
        </StyledHeader>

        <div className="not-header">
          {hasSearchButton && (
            <VenueSearch
              isCollapsed={isSearchMenuCollapsed}
              toggle={toggleSearchMenu}
            />
          )}

          <main>{children}</main>
        </div>
      </div>
    </StyledNav>
  );
}

export default Nav;
