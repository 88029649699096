import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const StyledTablesModal = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    height: 500px;
  }

  > div.search {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid grey;
    padding: ${theme.layout.small} ${theme.layout.medium};

    > label {
      padding: ${theme.layout.small};
      min-width: max-content;
      display: flex;
      align-items: center;
      gap: ${theme.layout.small};
    }

    @media only screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  > button {
    margin: ${theme.layout.medium};
    padding: ${theme.layout.small} ${theme.layout.medium};
  }

  > div.list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 55vh;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: ${theme.layout.small} ${theme.layout.medium};
      border-bottom: 1px solid ${theme.colour.lightGrey};
    }
  }
`;
