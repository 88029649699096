export enum Colours {
  DarkBlue = "#374250",
  DarkerBlue = "#2c3542",
  Blue = "#232ABC",
  Green = "#55B455",
  Red = "#FF4646",
  White = "#FFFFFF",
  OffWhite = "#F2F2F2",
  Black = "#202020",
  Grey = "#666666",
  LightGrey = "#E0E1E2",
  ExtraLightGrey = "#F7F7F7",
}

export enum FontSize {
  XSmall = "12px",
  Small = "14px",
  Medium = "16px",
  Large = "18px",
  XLarge = "28px",
  Title = "40px",
}

export enum FontWeight {
  Thin = 100,
  ExtraLight = 200,
  Light = 300,
  Normal = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Black = 900,
  ExtraBlack = 950,
}

export enum Layout {
  XSmall = "5px",
  Small = "10px",
  Medium = "20px",
  Large = "50px",
  XLarge = "75px",
}

export enum BorderRadius {
  Small = "5px",
  Medium = "8px",
}
