import styled from "styled-components";
import { theme } from "../../styles/theme";

export const ManageVenuesStyles = styled.div`
  max-width: 1440px;
  margin: auto;

  display: grid;
  gap: ${theme.layout.medium};

  > *:nth-child(1) {
    grid-column: 1 / -1;
  }

  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 800px) {
    grid-template-columns: repeat(6, 1fr);

    > * {
      grid-column: span 2;
    }

    > *:nth-child(2),
    > *:nth-child(3) {
      grid-column: span 3;
    }
  }
`;
