import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DayPickerInputProps } from "react-day-picker/types/Props";
import MomentLocaleUtils from "react-day-picker/moment";

import "moment/locale/en-gb";

interface Props extends DayPickerInputProps {
  className?: string;
}

//@ts-ignore
const classNames = DayPickerInput.defaultProps.classNames;
const { parseDate, formatDate } = MomentLocaleUtils;
export const DayPickerInputWrapper = ({ className = "", ...rest }: Props) => (
  <DayPickerInput
    formatDate={formatDate}
    parseDate={parseDate}
    dayPickerProps={{
      locale: "en-gb",
      localeUtils: MomentLocaleUtils,
    }}
    placeholder={`${formatDate(new Date(), "DD/MM/YYYY", "en-gb")}`}
    classNames={{
      ...classNames,
      container: `${classNames.container} ${className}`,
    }}
    {...rest}
  />
);
