import styled from "styled-components/macro";
import { Layout } from "../../styles/themeTypes";
import { theme } from "../../styles/theme";

interface Props {
  layout?: Layout;
  centered?: boolean;
}

const Container = styled.div<Props>`
  padding: ${(props) => props.layout};
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.colour.white};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.centered && "center"};
  justify-content: ${(props) => props.centered && "center"};
`;

Container.defaultProps = {
  layout: Layout.Medium,
};

export default Container;
