import React from "react";
import Nav from "../../components/nav/Nav";
import { useRouteMatch } from "react-router-dom";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../../settings/types";
import VenueInfoCard from "../../components/manageVenueCards/venueInfoCard/VenueInfoCard";
import { VenueContainer } from "../../state-containers/Venues";
import OverlayMessageCard from "../../components/manageVenueCards/OverlayMessageCard/OverlayMessageCard";
import OverlayMessageModal from "./overlayMessage/OverlayMessageModal";
import ManageTablesModal from "./manageTablesModal/ManageTablesModal";
import { ManageVenuesStyles } from "./ManageVenuesStyles";
import OpeningsTimeContainer from "./specialHours/addSpecialHours/segments/OpeningsTimeContainer";
import { HistoryContainer } from "../../state-containers/History";
import { DataLoader } from "@JS/nn4m-api-request";
import { useClientSettings } from "../../helpers/clientSettings";
import type CardType from "../../components/card/CardTypes";
import Card from "../../components/card/Card";

function ManageVenues() {
  const settings = SettingsContainer.useContainer();
  const { getClientSetting } = useClientSettings();
  const history = HistoryContainer.useContainer();

  const { selectedVenue, selectedVenueId } = VenueContainer.useContainer();
  const shownCards = getClientSetting<CardType[]>(SettingsKey.ManageVenueCards);

  // modal routes
  const showOverlayMessage = useRouteMatch("/manage-venues/overlay-message");
  const showManageTables = useRouteMatch("/manage-venues/tables");

  return (
    <Nav
      hasSearchButton
      back={() => history.goBack("/")}
      title={settings.string(SettingsKey.ManageVenuesPageTitle)}
    >
      {selectedVenueId ? (
        <DataLoader data={[selectedVenue]}>
          <ManageVenuesStyles>
            <VenueInfoCard {...selectedVenue.data!} />
            <OverlayMessageCard />
            <OpeningsTimeContainer />
            {shownCards.map((card) => (
              <Card key={card.title + card.link} {...card} />
            ))}

            <OverlayMessageModal
              open={Boolean(showOverlayMessage)}
              onClose={() => history.goBack("/manage-venues")}
            />
            <ManageTablesModal
              open={Boolean(showManageTables)}
              onClose={() => history.goBack("/manage-venues")}
            />
          </ManageVenuesStyles>
        </DataLoader>
      ) : (
        "oi theres nothing here"
      )}
    </Nav>
  );
}

export default ManageVenues;
