import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const StyledHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 275px;
  background-color: ${theme.colour.offWhite};
  text-align: center;

  .imageContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;

    img {
      min-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .inner {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${theme.layout.medium};

    h1 {
      color: ${theme.colour.white};
    }
  }
`;
