import styled from "styled-components/macro";
import { theme } from "../../../styles/theme";

export const StyledRule = styled.div<{ edited?: boolean }>`
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: ${theme.colour.white};
  border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
  padding: ${theme.layout.medium} ${theme.layout.medium} ${theme.layout.large}
    ${theme.layout.medium};
  margin-bottom: ${theme.layout.small};
  border-top: 3px
    ${(props) => (props.edited ? theme.colour.blue : theme.colour.green)} solid;

  h3 {
    font-weight: ${theme.fontWeight.light};
    color: ${theme.colour.grey};
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.layout.medium};

    .statusContainer {
      display: flex;

      .divider {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px ${theme.colour.lightGrey} solid;
        width: fit-content;
        height: fit-content;
      }

      > h3 {
        color: ${(props) =>
          props.edited ? theme.colour.blue : theme.colour.green};
      }
    }

    > :last-child {
      cursor: pointer;
      display: flex;

      h3 {
        margin-right: ${theme.layout.small};
      }
    }
  }
`;

StyledRule.defaultProps = {
  edited: false,
};
