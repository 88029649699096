import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

interface Props {
  variant?: "cta" | "primary" | "secondary" | "cancel" | "destructive";
  large?: boolean;
  disabled?: boolean;
}

const Button = styled.button<Props>`
  background-color: ${(props) =>
    props.variant === "cta"
      ? theme.colour.green
      : props.variant === "primary"
      ? theme.primaryColour
      : props.variant === "secondary"
      ? theme.colour.white
      : props.variant === "cancel"
      ? theme.colour.lightGrey
      : theme.colour.red};
  padding: ${theme.layout.small} //@ts-ignore
    ${(props) => (props.large ? theme.layout.large : theme.layout.medium)};
  color: ${(props) =>
    props.variant === "secondary"
      ? theme.colour.darkBlue
      : props.variant === "cancel"
      ? theme.colour.grey
      : theme.colour.white};
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSize.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.borderRadius.small};
  border: ${(props) =>
    props.variant === "secondary"
      ? `2px ${theme.colour.lightGrey} solid`
      : "none"};
  cursor: pointer;
  width: auto;
  height: fit-content;

  &:hover {
    filter: brightness(95%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      filter: brightness(100%);
    }
  }
`;

Button.defaultProps = {
  variant: "primary",
  large: false,
  disabled: false,
};

export default Button;
