import type { FunctionComponent } from "react";
import React from "react";
import { SettingsProvider } from "./Settings";
import { AuthProvider } from "./Auth";
import { RequestProvider } from "./Requests";
import { RCodeContainer } from "./RCode";
import { VenueContainer } from "./Venues";
import { MenuCombiningContainer } from "./MenuCombining";
import { HistoryContainer } from "./History";
import { VenueTablesContainer } from "./VenueTables";
import { AddSpecialHoursStateContainer } from "./AddSpecialHour";

const Providers = [
  // each provider has access to the subsequent ones
  VenueTablesContainer.Provider,
  AddSpecialHoursStateContainer.Provider,
  VenueContainer.Provider,
  RCodeContainer.Provider,
  MenuCombiningContainer.Provider,
  HistoryContainer.Provider,
  RequestProvider,
  AuthProvider,
  SettingsProvider,
] as FunctionComponent[];

export const CombinedProvider = ({ children }: { children: JSX.Element }) =>
  Providers.reduce((A, Provider) => <Provider>{A}</Provider>, children);
