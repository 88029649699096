import { useVenuesEndpoints } from "../api/venues";
import { createContainer } from "unstated-next";
import { useEffect, useState } from "react";
import { VenuesResponse } from "../api/types/venues";
import { VenueDetails } from "../api/types/venueDetails";
import { useClientRequestedData } from "../helpers/useClientRequestedData";
import { useClientState } from "../helpers/useClientState";
import { useRequestedData } from "@JS/nn4m-api-request";
import { useSpecialHoursEndpoints } from "../api/specialHours";
import { SpecialHoursResponse } from "../api/types/specialHours";

export type TimeZoneResponse = {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
};

export type SpecialHourDate = {
  closed: boolean;
  closingHour: string;
  date: string;
  label: string;
  openingHour: string;
};

export type SpecialHour = {
  dates: Array<SpecialHourDate>;
  label: string;
  venueId: number;
};

export interface SpecialHourResponse extends SpecialHour {
  id: number | undefined;
}

function useVenues() {
  const { getVenues, getVenueDetails } = useVenuesEndpoints();
  const { getSpecialHours } = useSpecialHoursEndpoints();

  const [selectedVenueId, setSelectedVenueId] = useClientState<
    number | undefined
  >(undefined);

  const [selectedVenueTimezone, setSelectedVenueTimeZone] =
    useState<TimeZoneResponse>();

  function selectVenue(venueId: number) {
    setSelectedVenueId(venueId);
  }

  // get list of all venues
  const { data: venues, fetchData: fetchVenues } = useClientRequestedData<
    [],
    VenuesResponse
  >(getVenues);

  const { data: specialHours, fetchData: fetchSpecialHours } = useRequestedData<
    [],
    SpecialHoursResponse
  >(getSpecialHours);

  useEffect(() => {
    fetchVenues();
  }, [fetchVenues]);

  // get more detailed data of selected venue once selected
  const { data: selectedVenue, fetchData: fetchVenueDetails } =
    useClientRequestedData<[number], VenueDetails>(getVenueDetails);

  useEffect(() => {
    if (selectedVenueId) {
      fetchVenueDetails(selectedVenueId);
    }
  }, [fetchVenueDetails, selectedVenueId]);

  return {
    venues,
    selectedVenue,
    selectedVenueId,
    selectVenue,
    selectedVenueTimezone,
    setSelectedVenueTimeZone,
    specialHours,
    fetchSpecialHours,
  };
}

export const VenueContainer = createContainer(useVenues);
