import React, { useRef } from "react";
import { Input, Dropdown, DropdownItemProps } from "semantic-ui-react";
import { StyledWrapper } from "./AutoCompletingInputStyles";

interface Props {
  suggestions: DropdownItemProps[];
  value: string;
  onInput(value: string): void;
  onSelect(value: string, isActive?: boolean): void;
}

const AutoCompletingInput = (p: Props) => {
  const inputRef = useRef<Input>(null);

  return (
    <StyledWrapper>
      <Input
        ref={inputRef}
        icon="search"
        iconPosition="left"
        placeholder="Search for Venue"
        value={p.value}
        onChange={(e, { value }) => p.onInput(value)}
      />
      {!!p.suggestions.length && (
        <Dropdown
          fluid
          icon={false}
          scrolling
          selection
          open
          options={p.suggestions}
          onChange={({ currentTarget }, { value }) => {
            p.onSelect(
              value as string,
              currentTarget.classList?.contains("active"),
            );
            // eslint-disable-next-line no-unused-expressions
            inputRef.current?.focus();
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default AutoCompletingInput;
