import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: ${theme.layout.medium};
  background-color: ${theme.colour.white};
  border-radius: ${theme.borderRadius.small};

  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(34, 36, 38, 0.15);

  .iconContainer {
    width: 100%;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.layout.medium};
    border-radius: ${theme.borderRadius.small};

    img {
      height: 50px;
    }
  }

  h4 {
    color: ${theme.colour.black};
    margin-bottom: ${theme.layout.small};
  }

  p {
    font-weight: ${theme.fontWeight.light};
    color: ${theme.colour.grey};
  }

  a,
  a > button {
    width: 100%;
    max-width: 175px;
  }
`;
