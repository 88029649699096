import { createContainer } from "unstated-next";
import { useState } from "react";
import {
  useCombinedMenusEndpoints,
  CombinedMenuResponse,
} from "../api/combinedMenus";
import { CombinedMenuRule } from "../pages/menuCombining/MenuCombiningTypes";
import { useRequestedData } from "@JS/nn4m-api-request";

function useMenuCombining() {
  const { getCombinedMenus } = useCombinedMenusEndpoints();
  const { data, fetchData } = useRequestedData<[], CombinedMenuResponse>(
    getCombinedMenus,
  );
  const [editedRules, setEditedRules] = useState<CombinedMenuRule[]>([]);

  return {
    fetchData,
    data,
    editedRules,
    setEditedRules,
  };
}

export const MenuCombiningContainer = createContainer(useMenuCombining);
