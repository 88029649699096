import React from "react";

// Components
import Container from "../../../../../components/container/ContainerStyles";
import Checkbox from "../../../../../components/checkbox/CheckboxStyles";
import DatePicker from "../../../../../components/datePicker/DatePickerStyles";
import Input from "../../../../../components/input/InputStyles";
import Button from "../../../../../components/button/ButtonStyles";
import TimeSelect from "../../../../../components/timeSelect/TimeSelect";

// Functions
import moment from "moment-timezone";
import { produce } from "immer";
import isEqual from "lodash/isEqual";

// Settings
import { SettingsKey } from "../../../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";

// State
import { AddSpecialHoursStateContainer } from "../../../../../state-containers/AddSpecialHour";
import { VenueContainer } from "../../../../../state-containers/Venues";
import { useAddSpecialHoursState } from "../AddSpecialHoursState";

// Types
import type { SpecialHourDate } from "../../../../../state-containers/Venues";

function AddedSpecialHours() {
  const { addedOpeningTimes, setAddedOpeningTimes } =
    AddSpecialHoursStateContainer.useContainer();
  const settings = SettingsContainer.useContainer();
  const { selectedVenueTimezone } = VenueContainer.useContainer();
  const {
    addNewOpeningTime,
    editSpecialHour,
    removeSpecialHour,
    handleSpecialHourIsClosed,
  } = useAddSpecialHoursState();

  const updateTime = (
    val: { hour: number; minute: number },
    addedOpeningTime: SpecialHourDate,
    time: "openingHour" | "closingHour",
  ) =>
    setAddedOpeningTimes((prevState) => {
      return produce(prevState, (draftState) => {
        const index = draftState.dates.findIndex((i) =>
          isEqual(i, addedOpeningTime),
        );
        draftState.dates[index][time] = moment()
          .hours(val.hour)
          .minutes(val.minute)
          .seconds(0)
          .format("HH:mm:ss");
      });
    });

  const datePickerOnChange = (
    newDate: Date,
    addedOpeningTime: SpecialHourDate,
  ) => {
    if (selectedVenueTimezone) {
      setAddedOpeningTimes((prevState) => {
        return produce(prevState, (draftState) => {
          const index = draftState.dates.findIndex((i) =>
            isEqual(i, addedOpeningTime),
          );
          draftState.dates[index].date = moment(newDate).format("YYYY-MM-DD");
        });
      });
    }
  };

  function formatHours(value: string) {
    const hour = value.substring(0, value.indexOf(":"));
    const minutesAndSeconds = value.substring(value.indexOf(":") + 1);
    const minutes = minutesAndSeconds.substr(0, minutesAndSeconds.indexOf(":"));
    return {
      hour: +hour,
      minute: +minutes,
    };
  }

  return (
    <Container>
      <div className="top">
        <p id="specialHoursLabel">
          {settings.string(SettingsKey.AddSpecialHoursEnterLabelTitle)}
        </p>
        <Input
          aria-labelledby="specialHoursLabel"
          onInput={(e) =>
            setAddedOpeningTimes((prevState) => {
              return { ...prevState, label: e.currentTarget.value };
            })
          }
          value={addedOpeningTimes.label}
          placeholder={settings.string(
            SettingsKey.AddSpecialHoursNamePlaceholder,
          )}
        />
        <Button onClick={addNewOpeningTime}>
          {settings.string(SettingsKey.AddSpecialHoursAddAnotherDate)}
        </Button>
      </div>
      <div className="bottom">
        {addedOpeningTimes.dates.map(
          (addedOpeningTime: SpecialHourDate, key: number) => {
            return (
              <div className="specialOpeningTime" key={key}>
                <hr />
                <p>
                  {settings.string(SettingsKey.AddSpecialHoursAddDisplayText)}
                </p>
                <Input
                  placeholder={settings.string(
                    SettingsKey.AddSpecialHoursAddDisplayTextPlaceholder,
                  )}
                  value={addedOpeningTime.label}
                  onInput={(e) =>
                    editSpecialHour(
                      addedOpeningTime,
                      "label",
                      e.currentTarget.value,
                    )
                  }
                />
                <div className="dateDetails">
                  <div>
                    <p>{settings.string(SettingsKey.AddSpecialHoursOpening)}</p>
                    <TimeSelect
                      updatefunc={(val) =>
                        updateTime(val, addedOpeningTime, "openingHour")
                      }
                      timeValue={formatHours(addedOpeningTime.openingHour)}
                      position="bottom center"
                      input={{
                        disabled: addedOpeningTime.closed,
                      }}
                    />
                  </div>
                  <div>
                    <p>{settings.string(SettingsKey.AddSpecialHoursClosing)}</p>
                    <TimeSelect
                      updatefunc={(val) =>
                        updateTime(val, addedOpeningTime, "closingHour")
                      }
                      timeValue={formatHours(addedOpeningTime.closingHour)}
                      position="bottom center"
                      input={{
                        disabled: addedOpeningTime.closed,
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      {settings.string(SettingsKey.AddSpecialHoursSelectDate)}
                    </p>
                    <DatePicker
                      value={new Date(addedOpeningTime.date)}
                      onDayChange={(newDate) =>
                        datePickerOnChange(newDate, addedOpeningTime)
                      }
                    />
                  </div>
                  <Button
                    variant="destructive"
                    onClick={() => removeSpecialHour(addedOpeningTime)}
                  >
                    {settings.string(SettingsKey.AddSpecialHoursRemoveNewDate)}
                  </Button>
                </div>
                <div
                  className="isClosed"
                  onClick={() => handleSpecialHourIsClosed(addedOpeningTime)}
                >
                  <Checkbox checked={addedOpeningTime.closed} />
                  <p>
                    {settings.string(SettingsKey.AddSpecialHoursDateClosed)}
                  </p>
                </div>
              </div>
            );
          },
        )}
      </div>
    </Container>
  );
}

export default AddedSpecialHours;
