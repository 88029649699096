import type { PropsWithChildren } from "react";
import React from "react";
import { SettingsProvider as NN4MSettingsProvider } from "@JS/nn4m-settings";
import { substitutionsManager } from "../settings/settings";

function SettingsProvider({ children }: PropsWithChildren<{}>) {
  return (
    <NN4MSettingsProvider
      canSwitchEnvironments
      substitutionsManager={substitutionsManager}
    >
      {children}
    </NN4MSettingsProvider>
  );
}

export { SettingsProvider };
