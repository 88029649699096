import { SettingsContainer } from "@JS/nn4m-settings";
import { DataLoader } from "@JS/nn4m-api-request";
import React from "react";
import Map from "../../../components/map/Map";
import Nav from "../../../components/nav/Nav";
import { SettingsKey } from "../../../settings/types";
import { HistoryContainer } from "../../../state-containers/History";
import { VenueContainer } from "../../../state-containers/Venues";
import {
  StyledViewMap,
  StyledInfoContainer,
  StyledMapContainer,
} from "./viewMapStyles";

const ViewMap = () => {
  const { selectedVenue, venues } = VenueContainer.useContainer();
  const history = HistoryContainer.useContainer();
  const settings = SettingsContainer.useContainer();

  const pubID = settings.string(SettingsKey.PubId, undefined, {
    ID: String(selectedVenue.data?.iOrderId),
  });
  const venueID = settings.string(SettingsKey.VenueId, undefined, {
    ID: String(selectedVenue.data?.venueId),
  });
  const salesAreaID = settings.string(SettingsKey.SalesAreaId, undefined, {
    ID: String(selectedVenue.data?.salesAreaId),
  });

  const mapVenueMarker = settings.string(SettingsKey.MapVenueMarker);

  const venueMarkers = venues?.data?.venues.map(({ lat, long, venueId }) => ({
    key: venueId,
    icon: mapVenueMarker,
    position: { lat, lng: long },
  }));

  return (
    <Nav
      hasSearchButton
      back={() => history.goBack("/manage-venues")}
      title={settings.string(SettingsKey.ViewMapPageTitle)}
    >
      <DataLoader data={[selectedVenue]}>
        <StyledViewMap>
          <StyledInfoContainer>
            <h2>{selectedVenue.data?.name}</h2>

            <span>
              <span>{pubID}</span>
              <span>{venueID}</span>
              <span>{salesAreaID}</span>
            </span>
          </StyledInfoContainer>

          <StyledMapContainer>
            <Map
              center={{
                lat: selectedVenue.data?.lat ?? 0,
                lng: selectedVenue.data?.long ?? 0,
              }}
              zoom={16}
              markers={venueMarkers}
            />
          </StyledMapContainer>
        </StyledViewMap>
      </DataLoader>
    </Nav>
  );
};

export default ViewMap;
