import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const StyledNav = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  > .not-sidebar {
    width: 100%;
    height: 100%;

    > .not-header {
      display: flex;
      height: calc(100% - 65px);

      > main {
        overflow-y: auto;
        width: 100%;
        padding: ${theme.layout.small} ${theme.layout.medium};
        margin: auto;
      }
    }
  }
`;

export const StyledHeader = styled.header`
  color: ${theme.colour.white};
  background-color: ${theme.primaryColour};
  font-size: ${theme.fontSize.large};

  display: flex;
  gap: ${theme.layout.medium};
  align-items: center;
  padding: ${theme.layout.medium};

  > button {
    color: ${theme.colour.white};
    background-color: transparent;
    border: none;
    cursor: pointer;

    > img {
      max-height: ${theme.fontSize.medium};
    }
  }
`;
