import React, { Fragment } from "react";
import { StyledSideMenu } from "./SideMenuStyles";
import { icons } from "../../assets/icons";
import useSideMenu from "./SideMenuState";
import { KeycloakContainer, KeycloakTokenParsed } from "@JS/nn4m-keycloak-auth";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../../settings/types";
import MenuItem from "./menuItem/MenuItem";
import { RCodeSelector } from "../rcodeSelector/RCodeSelector";

type SideMenuItem = {
  title: string;
  externalLink?: string;
  link?: string;
  icon?: string;
  activeIcon?: string;
  subMenuItems?: { title: string; link: string }[];
};

type SideMenuItems = {
  sideMenuItems: SideMenuItem[];
};

interface ExtendedParsedToken extends KeycloakTokenParsed {
  name: string;
}

interface Props {
  isCollapsed?: boolean;
}

function SideMenu({ isCollapsed = false }: Props) {
  const { openDropdown, setOpenDropdown, activeMenuItem, setActiveMenuItem } =
    useSideMenu();
  const { getParsedToken, logout } = KeycloakContainer.useContainer();
  const settings = SettingsContainer.useContainer();
  const menuItems = settings.json<SideMenuItems>(
    SettingsKey.SideMenuItems,
  ).sideMenuItems;

  return (
    <StyledSideMenu role="navigation" isCollapsed={isCollapsed}>
      <MenuItem>
        <div className="imageContainer">
          <img src={icons.profile} alt="profile" />
        </div>
        <h4>{getParsedToken<ExtendedParsedToken>()?.name}</h4>
      </MenuItem>
      <p onClick={logout}>Sign out</p>
      <RCodeSelector />
      {menuItems.map((menuItem, index) => (
        <Fragment key={index}>
          <MenuItem
            link={menuItem.link}
            externalLink={menuItem.externalLink}
            active={activeMenuItem === menuItem.title}
            onClick={() => {
              setActiveMenuItem(menuItem.title);
              if (menuItem.subMenuItems) {
                setOpenDropdown(
                  openDropdown === menuItem.title ? "" : menuItem.title,
                );
              }
            }}
            open={openDropdown === menuItem.title}
          >
            <div className="iconContainer">
              <img
                src={
                  menuItem.icon
                    ? activeMenuItem === menuItem.title
                      ? menuItem.icon
                      : menuItem.activeIcon
                    : activeMenuItem === menuItem.title
                    ? icons.home
                    : icons.home_inactive
                }
                alt={menuItem.title}
              />
            </div>
            <p>{menuItem.title}</p>
            {menuItem.subMenuItems && (
              <div className="dropdownIcon">
                <img src={icons.accordion_down} alt="accordion down" />
              </div>
            )}
          </MenuItem>
          {menuItem.subMenuItems?.map((subMenuItem, key) => (
            <MenuItem
              key={key}
              link={subMenuItem.link}
              visible={openDropdown === menuItems[index].title}
            >
              <div className="iconContainer" />
              <p>{subMenuItem.title}</p>
            </MenuItem>
          ))}
        </Fragment>
      ))}

      <div className="bottom">
        <img src={icons.logo} alt="logo" />
      </div>
    </StyledSideMenu>
  );
}

export default SideMenu;
