import React from "react";
import Container from "../../../../components/container/ContainerStyles";
import { theme } from "../../../../styles/theme";
import { icons } from "../../../../assets/icons";
import { SettingsKey } from "../../../../settings/types";
import { SettingsContainer } from "@JS/nn4m-settings";
import { Link } from "react-router-dom";
import Button from "../../../../components/button/ButtonStyles";
import { AddSpecialHoursStateContainer } from "../../../../state-containers/AddSpecialHour";

function NoSpecialHours() {
  const settings = SettingsContainer.useContainer();
  const { setAddedOpeningTimes, defaultSpecialHours } =
    AddSpecialHoursStateContainer.useContainer();

  return (
    <Container layout={theme.layout.xLarge} centered>
      <div className="containerInner">
        <div className="containerTitle">
          <img src={icons.specialHours} alt="clock" />
          <div>
            <h2>{settings!.string(SettingsKey.SpecialHoursNoHoursTitle)}</h2>
            <h3>
              {settings!.string(SettingsKey.SpecialHoursNoHoursDescription)}
            </h3>
          </div>
        </div>
        <Link to="/manage-venues/special-hours/add">
          <Button
            onClick={() => setAddedOpeningTimes(defaultSpecialHours)}
            large
          >
            {settings!.string(SettingsKey.SpecialHoursCTA)}
          </Button>
        </Link>
      </div>
    </Container>
  );
}

export default NoSpecialHours;
