import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import { Popup } from "semantic-ui-react";

export const StyledTimeSelect = styled(Popup)`
  .popUp {
    .sliderHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.layout.small};
    }

    > :first-child {
      margin-bottom: ${theme.layout.small};
    }

    .sliderContainer {
      .timeSlider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px ${theme.colour.lightGrey} solid;
        border-radius: ${theme.borderRadius.medium};
        padding: 0 ${theme.layout.medium};
        background-color: ${theme.colour.extraLightGrey};
        height: 40px;
        width: 100%;
        outline: none;
        font-size: ${theme.fontSize.small};
        font-weight: ${theme.fontWeight.normal};
        color: ${theme.colour.black};

        .slider {
          input {
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
      }
    }
  }
`;
