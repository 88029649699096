export enum Collection {
  GetVenues = "GetVenues",
  GetVenueDetails = "GetVenueDetails",
  GetCombinedMenus = "GetCombinedMenus",
  UpdateCombinedMenu = "UpdateCombinedMenu",
  DeleteCombinedMenu = "DeleteCombinedMenu",
  CreateCombinedMenu = "CreateCombinedMenu",
  PublishCombinedMenus = "PublishCombinedMenus",
  GetVenueTables = "GetVenueTables",
  UpdateVenueTables = "UpdateVenueTables",
  GetTimeZone = "GetTimeZone",
  GetSpecialHours = "GetSpecialHours",
  DeleteSpecialHour = "DeleteSpecialHour",
  UpdateSpecialHour = "UpdateSpecialHour",
  CreateSpecialHour = "CreateSpecialHour",
}

export enum SettingsKey {
  // Substitutions
  VenueType = "VenueType",
  AwsPublicBucket = "AwsPublicBucket",
  BackendUrl = "BackendUrl",

  // UI Strings
  Test = "Test",
  TestHeader = "TestHeader",

  // Home page
  HomeTiles = "HomeTiles",
  HeroTitle = "HeroTitle",
  HeroCTA = "HeroCTA",
  HeroImage = "HeroImage",
  SideMenuItems = "SideMenuItems",

  // Manage Venues
  ManageVenuesPageTitle = "ManageVenuesPageTitle",
  ManageVenuesAddress = "ManageVenuesAddress",
  ManageVenuesOpeningHours = "ManageVenuesOpeningHours",
  ManageVenuesAddOverlayTitle = "ManageVenuesAddOverlayTitle",
  ManageVenuesAddOverlayButton = "ManageVenuesAddOverlayButton",
  ManageVenuesAddOverlayNoOverlay = "ManageVenuesAddOverlayNoOverlay",
  ManageVenuesShownInfoLabels = "ManageVenuesShownInfoLabels",
  ManageVenueCards = "ManageVenueCards",

  // Venue Search
  Filter = "Filter",
  NoActiveFilters = "NoActiveFilters",
  VenueId = "VenueId",
  SalesAreaId = "SalesAreaId",
  PubId = "PubId",
  MaxVenuesInVenueSearch = "MaxVenuesInVenueSearch",
  ShowingResults = "ShowingResults",
  VenueFiltersAutocomplete = "VenueFiltersAutocomplete",

  // Special Hours Landing
  SpecialHoursPageTitle = "SpecialHoursPageTitle",
  SpecialHoursNoHoursTitle = "SpecialHoursNoHoursTitle",
  SpecialHoursNoHoursDescription = "SpecialHoursNoHoursDescription",
  SpecialHoursCTA = "SpecialHoursCTA",
  SpecialHoursNoSelectedVenueToast = "SpecialHoursNoSelectedVenueToast",
  SpecialHoursListTitle = "SpecialHoursListTitle",
  SpecialHoursDeleteSuccess = "SpecialHoursDeleteSuccess",
  SpecialHoursDeleteFail = "SpecialHoursDeleteFail",

  // Add Special Hours
  AddSpecialHoursPageTitle = "AddSpecialHoursPageTitle",
  AddSpecialHoursTitle = "AddSpecialHoursTitle",
  AddSpecialHoursEnterLabelTitle = "AddSpecialHoursEnterLabelTitle",
  AddSpecialHoursCancelButton = "AddSpecialHoursCancelButton",
  AddSpecialHoursSaveChanges = "AddSpecialHoursSaveChanges",
  AddSpecialHoursOpeningHours = "AddSpecialHoursOpeningHours",
  AddSpecialHoursOpeningHoursClosed = "AddSpecialHoursOpeningHoursClosed",
  AddSpecialHoursOpeningHoursOpen = "AddSpecialHoursOpeningHoursOpen",
  AddSpecialHoursNamePlaceholder = "AddSpecialHoursNamePlaceholder",
  AddSpecialHoursAddAnotherDate = "AddSpecialHoursAddAnotherDate",
  AddSpecialHoursAddDisplayText = "AddSpecialHoursAddDisplayText",
  AddSpecialHoursAddDisplayTextPlaceholder = "AddSpecialHoursAddDisplayTextPlaceholder",
  AddSpecialHoursOpening = "AddSpecialHoursOpening",
  AddSpecialHoursClosing = "AddSpecialHoursClosing",
  AddSpecialHoursSelectDate = "AddSpecialHoursSelectDate",
  AddSpecialHoursRemoveNewDate = "AddSpecialHoursRemoveNewDate",
  AddSpecialHoursDateClosed = "AddSpecialHoursDateClosed",
  AddSpecialHoursNoVenueSelectedToast = "AddSpecialHoursNoVenueSelectedToast",
  AddSpecialHoursUpdateHoursToastSuccess = "AddSpecialHoursUpdateHoursToastSuccess",
  AddSpecialHoursCreateHourToastSuccess = "AddSpecialHoursCreateHourToastSuccess",

  // Menu Combining
  MenuCombiningCancelEditing = "MenuCombiningCancelEditing",
  MenuCombiningChildID = "MenuCombiningChildID",
  MenuCombiningChildName = "MenuCombiningChildName",
  MenuCombiningConfirmChanges = "MenuCombiningConfirmChanges",
  MenuCombiningDeleteRule = "MenuCombiningDeleteRule",
  MenuCombiningEditRule = "MenuCombiningEditRule",
  MenuCombiningHideRule = "MenuCombiningHideRule",
  MenuCombiningLiveStatus = "MenuCombiningLiveStatus",
  MenuCombiningMakeLive = "MenuCombiningMakeLive",
  MenuCombiningMergeMethod = "MenuCombiningMergeMethod",
  MenuCombiningNewRule = "MenuCombiningNewRule",
  MenuCombiningPageDescription = "MenuCombiningPageDescription",
  MenuCombiningPageTitle = "MenuCombiningPageTitle",
  MenuCombiningParentID = "MenuCombiningParentID",
  MenuCombiningParentName = "MenuCombiningParentName",
  MenuCombiningPendingStatus = "MenuCombiningPendingStatus",
  MenuCombiningRuleStatus = "MenuCombiningRuleStatus",
  MenuCombiningShowRule = "MenuCombiningShowRule",
  MenuCombiningPublishFail = "MenuCombiningPublishFail",
  MenuCombiningPublishSuccess = "MenuCombiningPublishSuccess",
  MenuCombiningCreateSuccess = "MenuCombiningCreateSuccess",
  MenuCombiningCreateFail = "MenuCombiningCreateFail",
  MenuCombiningUpdateSuccess = "MenuCombiningUpdateSuccess",
  MenuCombiningUpdateFail = "MenuCombiningUpdateFail",
  MenuCombiningDeleteSuccess = "MenuCombiningDeleteSuccess",
  MenuCombiningDeleteFail = "MenuCombiningDeleteFail",
  MenuCombiningMergeMethodSingleEntry = "MenuCombiningMergeMethodSingleEntry",
  MenuCombiningMergeMethodSubMenus = "MenuCombiningMergeMethodSubMenus",
  MenuCombiningUndoDelete = "MenuCombiningUndoDelete",
  MenuCombiningSaveAllChangesSuccess = "MenuCombiningSaveAllChangesSuccess",
  MenuCombiningSaveAllChangesFail = "MenuCombiningSaveAllChangesFail",
  MenuCombiningLeaveWithoutSaving = "MenuCombiningLeaveWithoutSaving",
  MenuCombiningSaveAllChanges = "MenuCombiningSaveAllChanges",
  MenuCombiningLeaveModalTitle = "MenuCombiningLeaveModalTitle",
  MenuCombiningLeaveModalDescription = "MenuCombiningLeaveModalDescription",

  // View Map
  ViewMapPageTitle = "ViewMapPageTitle",
  MapVenueMarker = "MapVenueMarker",

  // Manage Tables
  ManageTablesTitle = "ManageTablesTitle",
  ManageTablesSubtitle = "ManageTablesSubtitle",
  ManageTablesSearchPlaceholder = "ManageTablesSearchPlaceholder",
  ManageTablesDisable = "ManageTablesDisable",
  ManageTablesRefresh = "ManageTablesRefresh",
  ManageTablesTable = "ManageTablesTable",
  ManageTablesCancelButton = "ManageTablesCancelButton",
  ManageTablesSaveChanges = "ManageTablesSaveChanges",
  ManageTablesReEnableText = "ManageTablesReEnableText",
  ManageTablesSecondsTillReEnable = "ManageTablesSecondsTillReEnable",
  ManageTablesDisabledEnabled = "ManageTablesDisabledEnabled",
  ManageTablesSaveSuccess = "ManageTablesSaveSuccess",
  ManageTablesSaveFail = "ManageTablesSaveFail",
  ManageTablesSomethingWentWrong = "ManageTablesSomethingWentWrong",

  // Keycloak
  KeycloakHost = "KeycloakHost",
  KeycloakRealm = "KeycloakRealm",
  KeycloakClientId = "KeycloakClientId",
  KeycloakAccessRole = "KeycloakAccessRole",

  // Other
  PhonePreview = "PhonePreview",
  GoogleMapsApiKey = "GoogleMapsApiKey",
}
