import { RequesterContainer } from "@JS/nn4m-api-request";
import { useCallback } from "react";
import { Collection } from "../settings/types";
import { CombinedMenuRule } from "../pages/menuCombining/MenuCombiningTypes";

export type CombinedMenuResponse = {
  rules: CombinedMenuRule[];
};

export function useCombinedMenusEndpoints() {
  const { requestFromCollection } = RequesterContainer.useContainer();

  const getCombinedMenus = useCallback((): Promise<CombinedMenuResponse> => {
    return requestFromCollection(Collection.GetCombinedMenus);
  }, [requestFromCollection]);

  const deleteCombinedMenu = useCallback(
    (id: string) => {
      return requestFromCollection(Collection.DeleteCombinedMenu, {
        RULE_ID: id,
      });
    },
    [requestFromCollection],
  );

  const updateCombinedMenu = useCallback(
    (id: string, body: CombinedMenuRule) => {
      return requestFromCollection(Collection.UpdateCombinedMenu, {
        RULE_ID: id,
        RULE: JSON.stringify(body),
      });
    },
    [requestFromCollection],
  );

  const createCombinedMenu = useCallback(
    (body: CombinedMenuRule) => {
      return requestFromCollection(Collection.CreateCombinedMenu, {
        RULE: JSON.stringify(body),
      });
    },
    [requestFromCollection],
  );

  const publishCombinedMenus = useCallback(() => {
    return requestFromCollection(Collection.PublishCombinedMenus);
  }, [requestFromCollection]);

  return {
    getCombinedMenus,
    deleteCombinedMenu,
    updateCombinedMenu,
    createCombinedMenu,
    publishCombinedMenus,
  };
}
