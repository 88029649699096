import styled from "styled-components/macro";
import { theme } from "../../../styles/theme";

export const StyledRuleExpanded = styled.div`
  > :first-child {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: unset;

    h3 {
      font-weight: ${theme.fontWeight.medium};
      color: ${theme.colour.black};
      margin-bottom: ${theme.layout.small};
    }

    > * {
      > div {
        margin-bottom: ${theme.layout.medium};
      }

      > :last-child {
        margin-bottom: 0;
      }

      .buttonContainer {
        display: flex;
        justify-content: space-between;
        gap: ${theme.layout.medium};
        margin-top: 50px;

        button {
          width: 60%;
        }

        > :first-child {
          width: 40%;
        }
      }
    }
  }
`;
