import { useClientSettings } from "./clientSettings";
import { SettingsKey } from "../settings/types";
import { substitutionsManager } from "../settings/settings";
import { RCodeContainer } from "../state-containers/RCode";

function useInitializeSubstitutions() {
  const { selectedRCode } = RCodeContainer.useContainer();
  const { getClientSetting } = useClientSettings();
  const VenueType = getClientSetting<string>(SettingsKey.VenueType);
  const AwsPublicBucket = getClientSetting<string>(SettingsKey.AwsPublicBucket);
  const BackendUrl = getClientSetting<string>(SettingsKey.BackendUrl);
  substitutionsManager.addSubstitution("VENUE_TYPE", VenueType);
  substitutionsManager.addSubstitution("AWS_PUBLIC_BUCKET", AwsPublicBucket);
  substitutionsManager.addSubstitution("BACKEND_URL", BackendUrl);
  substitutionsManager.addSubstitution("CLIENT_RCODE", selectedRCode);
}

export { useInitializeSubstitutions };
