import React from "react";
import { StyledHome } from "./HomeStyles";
import SideMenu from "../../components/sideMenu/SideMenu";
import Hero from "../../components/hero/Hero";
import Card from "../../components/card/Card";
import Grid from "../../components/grid/GridStyles";
import { SettingsKey } from "../../settings/types";
import type CardType from "../../components/card/CardTypes";
import { useClientSettings } from "../../helpers/clientSettings";
import { SettingsContainer } from "@JS/nn4m-settings";

function Home() {
  const { getClientSetting } = useClientSettings();
  const settings = SettingsContainer.useContainer();
  const tiles = getClientSetting<CardType[]>(SettingsKey.HomeTiles);
  const bannerImage = settings.string(SettingsKey.HeroImage);

  return (
    <StyledHome>
      <SideMenu />
      <main>
        <Hero bannerImage={bannerImage} />
        <Grid>
          {tiles.map((card) => (
            <Card key={card.title + card.link} {...card} />
          ))}
        </Grid>
      </main>
    </StyledHome>
  );
}

export default Home;
