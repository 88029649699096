import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const StyledSideMenu = styled.div<{ isCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${theme.primaryColour};
  width: ${({ isCollapsed }) => (isCollapsed ? "0" : "200px")};
  min-width: ${({ isCollapsed }) => (isCollapsed ? "0" : "200px")};
  height: 100%;
  padding-top: ${theme.layout.large};

  transition: ${theme.transition.normal};
  overflow-x: hidden;

  .button {
    width: min-content;
    margin-left: auto;
    background-color: unset;
    color: ${theme.colour.white};

    &:hover {
      color: ${theme.colour.white};
      * {
        cursor: pointer;
      }
    }
  }

  h4,
  > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${theme.colour.white};
  }

  > p {
    color: ${theme.colour.white};
    font-weight: ${theme.fontWeight.light};
    margin-left: 60px;
    margin-top: -10px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  > :first-child {
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  .bottom {
    margin-top: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 0 ${theme.layout.large} 0;
  }
`;
