import React, { useState, useEffect } from "react";
import Input from "../../../components/input/InputStyles";
import { Checkbox } from "semantic-ui-react";
import Modal from "../../../components/modal/Modal";
import { StyledTablesModal } from "./ManageTablesModalStyles";
import Button from "../../../components/button/ButtonStyles";
import { VenueTablesContainer } from "../../../state-containers/VenueTables";
import { useClientState } from "../../../helpers/useClientState";
import { VenueTable } from "../../../api/types/venueTables";
import { DataLoader } from "@JS/nn4m-api-request";
import { SettingsContainer } from "@JS/nn4m-settings";
import { SettingsKey } from "../../../settings/types";
import { toast } from "../../../components/toast/Toast";
import TimeTillReEnable from "./TimeTillReEnable";

interface Props {
  open: React.ComponentProps<typeof Modal>["open"];
  onClose(): void;
}

const ManageTablesModal = (props: Props) => {
  const settings = SettingsContainer.useContainer();
  const { venueTables, toggleVenueTables, refreshVenueTables } =
    VenueTablesContainer.useContainer();
  const [tablesToToggle, setTablesToToggle] = useClientState<VenueTable[]>([]);

  const [isShowingOnlyDisabled, setIsShowingOnlyDisabled] = useState(false);
  const toggleShowingOnlyDisabled = () => setIsShowingOnlyDisabled((x) => !x);

  const [query, setQuery] = useClientState("");

  useEffect(() => {
    if (props.open) {
      refreshVenueTables();
    }
  }, [props.open, refreshVenueTables]);

  async function save() {
    try {
      const changes = await toggleVenueTables(tablesToToggle);
      await refreshVenueTables();
      setTablesToToggle([]);

      const uiDisabledEnabled = settings.string(
        SettingsKey.ManageTablesDisabledEnabled,
        undefined,
        {
          ENABLED: String(changes.enable.length),
          DISABLED: String(changes.disable.length),
        },
      );

      toast.success(
        <>
          {uiSaveSuccess} <br />
          {uiDisabledEnabled}
        </>,
        { position: "bottom-center" },
      );
    } catch {
      toast.error(uiSaveFail, { position: "bottom-center" });
    }
  }

  function toggleTable(targetTable: VenueTable) {
    setTablesToToggle((prevTables) =>
      prevTables.includes(targetTable)
        ? prevTables.filter((table) => table !== targetTable)
        : [...prevTables, targetTable],
    );
  }

  const filteredTables = venueTables.data?.tables
    ?.filter((table) => String(table.table_number).includes(query))
    .filter(
      (table) =>
        !isShowingOnlyDisabled ||
        (tablesToToggle.includes(table) ? table.active : !table.active),
    );

  // ui strings
  const uiTitle = settings.string(SettingsKey.ManageTablesTitle);
  const uiSubtitle = settings.string(SettingsKey.ManageTablesSubtitle);
  const uiSearchPlaceholder = settings.string(
    SettingsKey.ManageTablesSearchPlaceholder,
  );
  const uiDisable = settings.string(SettingsKey.ManageTablesDisable);
  const uiCancelButton = settings.string(SettingsKey.ManageTablesCancelButton);
  const uiTable = settings.string(SettingsKey.ManageTablesTable);
  const uiSaveChanges = settings.string(SettingsKey.ManageTablesSaveChanges);
  const uiRefresh = settings.string(
    SettingsKey.ManageTablesRefresh,
    undefined,
    {
      DISPLAYED_TABLES: String(filteredTables?.length),
      MAX_TABLES: String(venueTables.data?.tables?.length),
    },
  );
  const uiSaveSuccess = settings.string(SettingsKey.ManageTablesSaveSuccess);
  const uiSaveFail = settings.string(SettingsKey.ManageTablesSaveFail);

  return (
    <Modal
      {...props}
      title={
        <>
          {uiTitle} <small>{uiSubtitle}</small>
        </>
      }
      actions={
        <>
          <Button variant="cancel" onClick={props.onClose}>
            {uiCancelButton}
          </Button>
          <Button
            variant="cta"
            onClick={save}
            disabled={!tablesToToggle.length}
          >
            {uiSaveChanges}
          </Button>
        </>
      }
    >
      <StyledTablesModal>
        <div className="search">
          <Input
            type="number"
            min={0}
            placeholder={uiSearchPlaceholder}
            onChange={(_e, { value }) => setQuery(value)}
          />
          <label>
            {uiDisable}
            <Checkbox
              radio
              checked={isShowingOnlyDisabled}
              onClick={toggleShowingOnlyDisabled}
            />
          </label>
        </div>

        <DataLoader isInModal data={[venueTables]}>
          <Button onClick={refreshVenueTables}>{uiRefresh}</Button>

          <div className="list">
            {filteredTables?.map((table) => (
              <div role="listitem" key={table.id}>
                {uiTable} {table.table_number}
                {table?.deactivated_date?.date && (
                  <TimeTillReEnable date={table.deactivated_date.date} />
                )}
                <Checkbox
                  toggle
                  checked={Boolean(
                    tablesToToggle.includes(table)
                      ? table.active
                      : !table.active,
                  )}
                  onClick={() => toggleTable(table)}
                />
              </div>
            ))}
          </div>
        </DataLoader>
      </StyledTablesModal>
    </Modal>
  );
};

export default ManageTablesModal;
