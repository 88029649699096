import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import { Input as SemanticInput } from "semantic-ui-react";

const Input = styled(SemanticInput)`
  width: 100%;

  input {
    border: 1px ${theme.colour.lightGrey} solid;
    border-radius: ${theme.borderRadius.medium};
    padding: 0 ${theme.layout.medium};
    background-color: ${theme.colour.extraLightGrey};
    height: 40px;
    width: 100%;
    outline: none;
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.normal};
    color: ${theme.colour.black};
  }
`;

export default Input;
